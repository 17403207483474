import React from 'react';
import { Helmet } from 'react-helmet';
import { Heart, Image, Shield, Zap } from 'lucide-react';

const About = () => {
  const features = [
    {
      icon: <Image className="w-6 h-6 text-purple-500" />,
      title: "Free Image Tools",
      description: "Professional-grade tools for everyone, completely free."
    },
    {
      icon: <Shield className="w-6 h-6 text-purple-500" />,
      title: "Privacy First",
      description: "All processing happens in your browser. Your files stay private."
    },
    {
      icon: <Zap className="w-6 h-6 text-purple-500" />,
      title: "Fast Processing",
      description: "Modern technology for quick and efficient image processing."
    }
  ];

  return (
    <>
      <Helmet>
        <title>About ilikeimg - Free Online Image Tools</title>
        <meta name="description" content="Learn about ilikeimg and our mission to provide free, high-quality image tools for everyone" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <Heart className="w-12 h-12 text-purple-500 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900 mb-4">About ilikeimg</h1>
          <p className="text-xl text-gray-600">
            Making image editing tools accessible to everyone
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {features.map((feature, index) => (
            <div key={index} className="text-center p-6">
              <div className="inline-block p-3 bg-purple-100 rounded-full mb-4">
                {feature.icon}
              </div>
              <h2 className="text-lg font-semibold text-gray-900 mb-2">{feature.title}</h2>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="prose prose-purple max-w-none">
          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Our Mission</h2>
          <p className="text-gray-600 mb-4">
            We believe that everyone should have access to professional-grade image tools.
            That's why we've created ilikeimg - a suite of free, easy-to-use online tools
            that help you work with your images quickly and efficiently.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Privacy & Security</h2>
          <p className="text-gray-600 mb-4">
            Your privacy is our top priority. All image processing happens right in your browser -
            your files never leave your device. We don't store any of your images or personal information.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
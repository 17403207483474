import React from 'react';
import { Helmet } from 'react-helmet';

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service - ilikeimg</title>
        <meta name="description" content="Terms of service for ilikeimg - Understanding our terms of use" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">1. Terms</h2>
          <p className="text-gray-600 mb-4">
            By accessing ilikeimg, you agree to be bound by these terms of service and comply with all applicable laws and regulations.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">2. Use License</h2>
          <p className="text-gray-600 mb-4">
            Our tools are provided free of charge for personal and commercial use. 
            You may not use our services for any illegal or unauthorized purpose.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">3. Disclaimer</h2>
          <p className="text-gray-600 mb-4">
            Our services are provided "as is" without any warranties, expressed or implied.
            We do not guarantee that our services will be uninterrupted or error-free.
          </p>

          {/* Add more sections as needed */}
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
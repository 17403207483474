import React from 'react';
import { Helmet } from 'react-helmet';
import { Calendar, User, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, excerpt, date, author, readTime, slug, category }) => (
  <article className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden">
    <div className="p-6">
      <div className="flex items-center space-x-4 mb-4">
        <span className="text-sm font-medium text-purple-600 bg-purple-50 px-3 py-1 rounded-full">
          {category}
        </span>
      </div>
      <h2 className="text-xl font-semibold text-gray-900 mb-2">
        <Link to={`/blog/${slug}`} className="hover:text-purple-600 transition-colors">
          {title}
        </Link>
      </h2>
      <p className="text-gray-600 mb-4">{excerpt}</p>
      <div className="flex items-center text-sm text-gray-500 space-x-4">
        <div className="flex items-center">
          <Calendar className="w-4 h-4 mr-1" />
          {date}
        </div>
        <div className="flex items-center">
          <User className="w-4 h-4 mr-1" />
          {author}
        </div>
        <div className="flex items-center">
          <Clock className="w-4 h-4 mr-1" />
          {readTime} min read
        </div>
      </div>
    </div>
  </article>
);

const Blog = () => {
  const posts = [
    {
      title: "Understanding Image Formats: JPG vs PNG vs WebP",
      excerpt: "Learn about different image formats and when to use each one for optimal results.",
      date: "2024-01-15",
      author: "John Doe",
      readTime: 5,
      slug: "understanding-image-formats",
      category: "Education"
    },
    {
      title: "Tips for Optimizing Images for Web",
      excerpt: "Best practices for preparing images for your website to improve loading times.",
      date: "2024-01-10",
      author: "Jane Smith",
      readTime: 4,
      slug: "image-optimization-tips",
      category: "Tips & Tricks"
    },
    {
      title: "The Future of Image Compression",
      excerpt: "Exploring new technologies and formats for better image compression.",
      date: "2024-01-05",
      author: "Mike Johnson",
      readTime: 6,
      slug: "future-of-compression",
      category: "Technology"
    }
  ];

  return (
    <>
      <Helmet>
        <title>Blog - ilikeimg</title>
        <meta name="description" content="Read our latest articles about image processing, optimization, and web development." />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">Blog</h1>
          <p className="text-xl text-gray-600">
            Tips, tutorials, and updates from the ilikeimg team
          </p>
        </div>

        <div className="grid gap-8">
          {posts.map((post, index) => (
            <BlogPost key={index} {...post} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;
import * as LucideIcons from 'lucide-react';

export const FORMAT_CATEGORIES = {
  'Convert': [
    {
      path: '/convert-to-jpg',
      name: 'To JPG',
      description: 'High-quality images',
      icon: <LucideIcons.Image className="w-4 h-4" />
    },
    {
      path: '/convert-to-png',
      name: 'To PNG',
      description: 'With transparency',
      icon: <LucideIcons.Image className="w-4 h-4" />
    },
    {
      path: '/convert-to-webp',
      name: 'To WebP',
      description: 'Modern web format',
      icon: <LucideIcons.Image className="w-4 h-4" />
    },
    {
      path: '/convert-to-pdf',
      name: 'To PDF',
      description: 'Document format',
      icon: <LucideIcons.FileType className="w-4 h-4" />
    }
  ],
  'Edit': [
    {
      path: '/image-crop',
      name: 'Crop',
      description: 'Adjust dimensions',
      icon: <LucideIcons.Crop className="w-4 h-4" />
    },
    {
      path: '/image-compress',
      name: 'Compress',
      description: 'Reduce file size',
      icon: <LucideIcons.Minimize2 className="w-4 h-4" />
    },
    {
      path: '/image-resize',
      name: 'Resize',
      description: 'Change size',
      icon: <LucideIcons.Move className="w-4 h-4" />
    },
    {
      path: '/image-upscale',
      name: 'Upscale',
      description: 'Increase resolution',
      icon: <LucideIcons.Maximize2 className="w-4 h-4" />
    }
  ]
};
  
// Input format support configuration
export const SUPPORTED_FORMATS = {
  // Standard Images
  '.jpg': { mime: 'image/jpeg', category: 'standard', previewable: true },
  '.jpeg': { mime: 'image/jpeg', category: 'standard', previewable: true },
  '.png': { mime: 'image/png', category: 'standard', previewable: true },
  '.webp': { mime: 'image/webp', category: 'standard', previewable: true },
  '.gif': { mime: 'image/gif', category: 'standard', previewable: true },
  
  // Professional Formats
  '.tiff': { mime: 'image/tiff', category: 'professional', previewable: false },
  '.tif': { mime: 'image/tiff', category: 'professional', previewable: false },
  '.bmp': { mime: 'image/bmp', category: 'standard', previewable: true },
  '.ico': { mime: 'image/x-icon', category: 'special', previewable: true },
  
  // Design Formats
  '.psd': { mime: 'image/vnd.adobe.photoshop', category: 'professional', previewable: false },
  '.eps': { mime: 'application/postscript', category: 'professional', previewable: false },
};
  
// Output format configuration
export const OUTPUT_FORMATS = {
  'jpg': { 
    name: 'JPG',
    mime: 'image/jpeg',
    supportsQuality: true,
    description: 'Best for photos',
    path: '/convert-to-jpg'
  },
  'png': { 
    name: 'PNG',
    mime: 'image/png',
    supportsQuality: false,
    description: 'Best for graphics with transparency',
    path: '/convert-to-png'
  },
  'webp': { 
    name: 'WebP',
    mime: 'image/webp',
    supportsQuality: true,
    description: 'Modern efficient format',
    path: '/convert-to-webp'
  },
  'pdf': { 
    name: 'PDF',
    mime: 'application/pdf',
    supportsQuality: false,
    description: 'Document format',
    path: '/convert-to-pdf'
  }
};

// Constants
export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

// Utility functions
export const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const getCustomFileName = (originalName, outputFormat) => {
  const timestamp = new Date().toISOString()
    .replace(/[-:]/g, '')
    .replace('T', '_')
    .slice(0, -5);

  const baseName = originalName
    .split('.')[0]
    .replace(/[^\w-]/g, '_');

  return `ilikeimg.com_${baseName}_${timestamp}.${outputFormat}`;
};

export const getInputFormatsForDisplay = () => {
  const formatsByCategory = {};
  Object.entries(SUPPORTED_FORMATS).forEach(([ext, info]) => {
    if (!formatsByCategory[info.category]) {
      formatsByCategory[info.category] = [];
    }
    formatsByCategory[info.category].push(ext.replace('.', '').toUpperCase());
  });
  return formatsByCategory;
};

export const getPreviewableFormats = () => 
  Object.entries(SUPPORTED_FORMATS)
    .filter(([_, info]) => info.previewable)
    .map(([ext]) => ext);

export const getSupportedExtensions = () => 
  Object.keys(SUPPORTED_FORMATS);

export const getAvailableOutputFormats = () =>
  Object.entries(OUTPUT_FORMATS).map(([id, info]) => ({
    id,
    ...info
  }));

export const getSupportedFormatsDisplay = () => {
  const categories = {
    'standard': 'Common Formats',
    'professional': 'Professional',
    'special': 'Special'
  };
  
  const formatsByCategory = getInputFormatsForDisplay();
  
  return Object.entries(categories)
    .filter(([key]) => formatsByCategory[key]?.length > 0)
    .map(([key, label]) => ({
      category: label,
      formats: formatsByCategory[key]
    }));
};

/**
 * Gets all supported MIME types from SUPPORTED_FORMATS
 * @returns {string[]} Array of MIME types
 */
export const getSupportedMimeTypes = () => {
  return Object.values(SUPPORTED_FORMATS)
    .map(format => format.mime)
    .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
};

/**
 * Validates a file for size and format
 * @param {File} file - The file to validate
 * @returns {string[]} Array of error messages (empty if valid)
 */
export const validateFile = (file) => {
  const errors = [];
  
  // Check file size
  if (file.size > MAX_FILE_SIZE) {
    errors.push(`File "${file.name}" is too large (${formatFileSize(file.size)}). Maximum size is ${formatFileSize(MAX_FILE_SIZE)}`);
  }

  // Check file format
  const extension = `.${file.name.split('.').pop().toLowerCase()}`;
  if (!Object.keys(SUPPORTED_FORMATS).includes(extension)) {
    const supportedExtList = Object.keys(SUPPORTED_FORMATS)
      .map(ext => ext.replace('.', '').toUpperCase())
      .join(', ');
    errors.push(`File type "${extension}" is not supported. Supported formats: ${supportedExtList}`);
  }

  return errors;
};
import React, { useEffect, useState } from 'react';
import { Star } from 'lucide-react';

const reviews = [
    {
        name: "Sarah M.",
        role: "Professional Photographer",
        comment: "This AI upscaling tool is a game-changer! I've used it to enhance old photos and the results are incredible. The detail preservation is remarkable.",
        rating: 5,
        date: "2 days ago"
    },
    {
        name: "David K.",
        role: "Digital Artist",
        comment: "Perfect for my artwork. The detail preservation is amazing and the process is so quick! I can now offer larger prints to my clients.",
        rating: 5,
        date: "1 week ago"
    },
    {
        name: "Emily R.",
        role: "E-commerce Owner",
        comment: "We use this for all our product photos now. The quality improvement is remarkable, and our conversion rates have increased significantly.",
        rating: 5,
        date: "3 days ago"
    },
    {
        name: "Michael L.",
        role: "Web Developer",
        comment: "Finally, a tool that actually delivers on its promises. The results are consistently excellent, and the API integration was seamless.",
        rating: 5,
        date: "5 days ago"
    },
    {
        name: "Jessica T.",
        role: "Content Creator",
        comment: "This has become an essential part of my content creation workflow. The upscaled images look amazing on high-resolution displays!",
        rating: 5,
        date: "1 week ago"
    },
    {
        name: "Alex P.",
        role: "Graphic Designer",
        comment: "The AI understands exactly what details to enhance. I'm impressed by how it maintains the original image's integrity while increasing resolution.",
        rating: 5,
        date: "4 days ago"
    }
];

const ReviewCard = ({ review }) => (
    <div className="w-full px-4">
        <div className="bg-gray-50 p-6 rounded-xl border border-gray-100 hover:bg-white hover:shadow-md transition-all duration-300">
            <div className="flex items-center mb-4">
                <div className="flex items-center">
                    {[...Array(review.rating)].map((_, i) => (
                        <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" />
                    ))}
                </div>
            </div>
            <p className="text-gray-600 mb-4 line-clamp-4">{review.comment}</p>
            <div className="flex justify-between items-center">
                <div>
                    <p className="font-medium text-gray-900">{review.name}</p>
                    <p className="text-sm text-gray-500">{review.role}</p>
                </div>
                <span className="text-sm text-gray-400">{review.date}</span>
            </div>
        </div>
    </div>
);

const PaginationDot = ({ active, onClick }) => (
    <button
        className={`w-2 h-2 rounded-full mx-1 transition-all duration-300 
                 ${active ? 'bg-purple-500 w-4' : 'bg-gray-300 hover:bg-purple-300'}`}
        onClick={onClick}
    />
);

const ReviewCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const totalSlides = Math.ceil(reviews.length / 3); // Show 3 reviews at a time

    useEffect(() => {
        if (isPaused) return;

        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % totalSlides);
        }, 5000); // Change slides every 5 seconds

        return () => clearInterval(timer);
    }, [isPaused, totalSlides]);

    const handleDotClick = (index) => {
        setCurrentSlide(index);
        setIsPaused(true);
        setTimeout(() => setIsPaused(false), 5000); // Resume auto-sliding after 5 seconds
    };

    return (
        <div className="py-12 bg-gradient-to-b from-white to-gray-50">
            <div className="max-w-7xl mx-auto px-4">
                <h3 className="text-2xl font-bold text-center text-gray-900 mb-8">
                    What Our Users Say
                </h3>
                
                <div 
                    className="relative overflow-hidden"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                >
                    <div 
                        className="flex transition-transform duration-500 ease-in-out"
                        style={{ 
                            transform: `translateX(-${currentSlide * 100}%)`,
                        }}
                    >
                        <div className="flex flex-nowrap min-w-full">
                            {Array(totalSlides).fill(0).map((_, slideIndex) => (
                                <div 
                                    key={slideIndex} 
                                    className="min-w-full grid grid-cols-1 md:grid-cols-3 gap-6"
                                >
                                    {reviews.slice(slideIndex * 3, (slideIndex + 1) * 3).map((review, index) => (
                                        <ReviewCard 
                                            key={`${slideIndex}-${index}`} 
                                            review={review} 
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination Dots */}
                    <div className="flex justify-center items-center mt-8 space-x-2">
                        {Array(totalSlides).fill(0).map((_, index) => (
                            <PaginationDot
                                key={index}
                                active={currentSlide === index}
                                onClick={() => handleDotClick(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewCarousel;
import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Upload, AlertCircle, ChevronRight, X, Loader } from 'lucide-react';
import DownloadFile from './DownloadFile';
import { trackEvent, trackButtonClick, trackFileUpload } from '../utils/analytics';
import { isDevelopment, logInDev } from '../utils/environment';

const ImagePreview = ({ image, label, onRemove, isCompact = false }) => (
    <div 
      className={`relative group transition-all duration-300 ease-in-out
        ${isCompact 
          ? 'w-36 h-36 sm:w-48 sm:h-48' 
          : 'w-40 h-40 sm:w-64 sm:h-64'}`}
    >
      <div className="relative w-full h-full flex items-center justify-center bg-gray-50 rounded-lg overflow-hidden border border-gray-200 transition-all duration-300">
        <img 
          src={image} 
          alt={label}
          className="max-w-full max-h-full object-contain"
        />
        <div className="absolute top-2 left-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
          {label}
        </div>
        {onRemove && (
          <button
            onClick={onRemove}
            className="absolute top-2 right-2 bg-black bg-opacity-50 text-white p-1.5 rounded-full hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100 sm:opacity-0"
            aria-label="Remove image"
          >
            <X className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
  
  const UploadBox = ({ onClick, label, isCompact = false }) => (
    <div
      onClick={onClick}
      className={`transition-all duration-300 ease-in-out cursor-pointer 
        ${isCompact 
          ? 'w-36 h-36 sm:w-48 sm:h-48' 
          : 'w-40 h-40 sm:w-64 sm:h-64'}
        rounded-lg border-2 border-dashed border-gray-300 hover:border-purple-500 
        flex flex-col items-center justify-center p-4 sm:p-6`}
    >
      <Upload className="h-8 w-8 sm:h-12 sm:w-12 text-gray-400" />
      <p className="mt-2 sm:mt-4 text-xs sm:text-sm font-medium text-gray-900 text-center">{label}</p>
      <p className="mt-1 text-xs text-gray-500 hidden sm:block">Click to upload</p>
    </div>
  );
  
  const ResultPreview = ({ image, isLoading }) => (
    <div className="w-full max-w-2xl mt-6 sm:mt-8">
      <div className="bg-gray-50 rounded-lg p-4 sm:p-6">
        <h3 className="text-base sm:text-lg font-medium text-gray-900 mb-4">Result</h3>
        <div className="relative w-full aspect-video bg-white rounded-lg overflow-hidden">
          {isLoading ? (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-50">
              <Loader className="w-8 h-8 sm:w-12 sm:h-12 text-purple-500 animate-spin mb-3 sm:mb-4" />
              <p className="text-sm sm:text-base text-gray-600 font-medium">Merging faces...</p>
              <p className="text-xs sm:text-sm text-gray-500 mt-1 sm:mt-2">This may take a few moments</p>
            </div>
          ) : (
            <img 
              src={image} 
              alt="Merged Result"
              className="w-full h-full object-contain"
            />
          )}
        </div>
      </div>
    </div>
  );

const MergeFaces = () => {
  const [state, setState] = useState({
    faceImage: null,
    targetImage: null,
    isProcessing: false,
    error: null,
    mergedImage: null,
    showDownload: false,
  });

  const faceImageInputRef = useRef(null);
  const targetImageInputRef = useRef(null);

  useEffect(() => {
    if (isDevelopment) {
      logInDev('MergeFaces Component State:', state);
    }
  }, [state]);

  const MAX_DIMENSION = 1024;

  const resizeImage = async (dataUrl, fileName) => {
    // Create an image object
    const img = new Image();
    await new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
      img.src = dataUrl;
    });
  
    const originalWidth = img.width;
    const originalHeight = img.height;
    
    // Check if resizing is needed
    const maxDimension = Math.max(originalWidth, originalHeight);
    if (maxDimension <= MAX_DIMENSION) {
      if (isDevelopment) {
        logInDev(`No resize needed for ${fileName}:`, `${originalWidth}x${originalHeight}`);
      }
      return dataUrl;
    }
  
    // Calculate new dimensions
    let newWidth, newHeight;
    if (originalWidth > originalHeight) {
      newWidth = MAX_DIMENSION;
      newHeight = Math.round((originalHeight * MAX_DIMENSION) / originalWidth);
    } else {
      newHeight = MAX_DIMENSION;
      newWidth = Math.round((originalWidth * MAX_DIMENSION) / originalHeight);
    }
  
    // Create canvas and resize
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext('2d');
    
    // Use better quality interpolation
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';
    
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
    // Convert to blob and then to data URL
    const resizedDataUrl = canvas.toDataURL('image/jpeg', 0.95);
  
    if (isDevelopment) {
      logInDev(`Resized ${fileName}:`, `${originalWidth}x${originalHeight} → ${newWidth}x${newHeight}`);
    }
  
    return resizedDataUrl;
  };
  
  const handleFile = async (file, type) => {
    try {
      trackFileUpload(file);
      trackEvent('face_merge_upload', {
        file_type: type,
        file_size: file.size,
        file_name: file.name
      });
  
      const reader = new FileReader();
      reader.onload = async (e) => {
        const dataUrl = e.target.result;
        const resizedDataUrl = await resizeImage(dataUrl, file.name);
        
        setState(prev => ({
          ...prev,
          [type]: resizedDataUrl,
          error: null
        }));
      };
      reader.readAsDataURL(file);
    } catch (error) {
      setState(prev => ({ 
        ...prev, 
        error: `Error processing ${file.name}: ${error.message}` 
      }));
      trackEvent('face_merge_error', {
        error_type: 'upload',
        error_message: error.message,
        file_type: type
      });
    }
  };
  
  const handleMerge = async () => {
    try {
      setState(prev => ({ ...prev, isProcessing: true, error: null }));
      
      const startTime = performance.now();
      trackEvent('face_merge_start', {
        timestamp: new Date().toISOString()
      });
  
      const formData = new FormData();
      
      // Convert base64 to blobs
      const faceBlob = await fetch(state.faceImage).then(r => r.blob());
      const targetBlob = await fetch(state.targetImage).then(r => r.blob());
      
      formData.append('face_image', faceBlob);
      formData.append('target_image', targetBlob);
      formData.append('output_format', 'png');
  
      if (isDevelopment) {
        logInDev('Sending merge request with images:', {
          faceImage: `${Math.round(faceBlob.size / 1024)}KB`,
          targetImage: `${Math.round(targetBlob.size / 1024)}KB`
        });
      }
  
      const apiUrl = (process.env.REACT_APP_API_URL || '').replace(/\/$/, '');
      const response = await fetch(`${apiUrl}/merge-faces`, {
        method: 'POST',
        body: formData
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        let errorMessage;
        try {
          const errorJson = JSON.parse(errorText);
          errorMessage = errorJson.detail || errorJson.error || errorText;
        } catch {
          errorMessage = errorText;
        }
        throw new Error(errorMessage || 'Face merge failed');
      }
  
      const blob = await response.blob();
      const mergedImageUrl = URL.createObjectURL(blob);
      
      const endTime = performance.now();
      const processingTime = endTime - startTime;
      
      trackEvent('face_merge_complete', {
        processing_time_ms: processingTime,
        output_size: blob.size,
        success: true,
        timestamp: new Date().toISOString()
      });
  
      setState(prev => ({
        ...prev,
        mergedImage: mergedImageUrl,
        downloadFiles: [{
          name: `ilikeimg_merged_${new Date().toISOString().replace(/[:.]/g, '')}.png`,
          blob: blob
        }],
        isProcessing: false,
      }));
  
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error.message,
        isProcessing: false,
      }));
  
      trackEvent('face_merge_error', {
        error_message: error.message,
        error_type: 'processing',
        timestamp: new Date().toISOString()
      });
  
      if (isDevelopment) {
        logInDev('Face merge error:', error);
      }
    }
  };
  const handleRemoveImage = (type) => {
    trackButtonClick(`remove_${type}`, `Remove ${type === 'faceImage' ? 'Face' : 'Target'} Image`);
    setState(prev => ({ ...prev, [type]: null }));
  };

 
  const handleSave = () => {
    trackButtonClick('face_merge_save', 'Save Merged Image');
    setState(prev => ({
      ...prev,
      showDownload: true
    }));
  };

  const resetState = () => {
    setState({
      faceImage: null,
      targetImage: null,
      isProcessing: false,
      error: null,
      mergedImage: null,
      showDownload: false,
    });
  };

  const isCompact = state.isProcessing || state.mergedImage;

 return (
    <>
      <Helmet>
        <title>Merge Faces - AI Face Swap Tool | ilikeimg</title>
        <meta name="description" content="Merge two faces using AI technology. Upload a source face and target image to create a seamless face swap." />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <div className="flex-grow flex flex-col items-center py-4 sm:py-8 px-4">
          {!(state.faceImage && state.targetImage) && (
            <div className="text-center max-w-2xl w-full mb-6 sm:mb-8">
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
                Face Merge
              </h2>
              <p className="text-base sm:text-lg text-gray-600">
                Upload a source face and target image to merge them using AI technology.
              </p>
            </div>
          )}

          {state.error && (
            <div className="w-full max-w-2xl mb-6 sm:mb-8">
              <div className="flex items-start space-x-2 bg-red-50 text-red-800 p-4 rounded-lg">
                <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
                <span className="text-sm">{state.error}</span>
              </div>
            </div>
          )}

          <div className="w-full max-w-4xl flex flex-col items-center">
            {state.showDownload ? (
              <DownloadFile
                files={state.downloadFiles}
                onBack={resetState}
                toolName="Face Merge"
                toolIdentifier="face-merge"
              />
            ) : (
              <div className="flex flex-col items-center">
                {/* Updated preview container with improved mobile layout */}
                <div className="flex flex-row items-start justify-center gap-4 sm:gap-8 mb-6 sm:mb-8">
                  {!state.faceImage ? (
                    <UploadBox 
                      onClick={() => faceImageInputRef.current?.click()}
                      label="Upload Source Face"
                      isCompact={isCompact}
                    />
                  ) : (
                    <ImagePreview 
                      image={state.faceImage}
                      label="Source Face"
                      onRemove={() => handleRemoveImage('faceImage')}
                      isCompact={isCompact}
                    />
                  )}

                  {!state.targetImage ? (
                    <UploadBox 
                      onClick={() => targetImageInputRef.current?.click()}
                      label="Upload Target Image"
                      isCompact={isCompact}
                    />
                  ) : (
                    <ImagePreview 
                      image={state.targetImage}
                      label="Target Image"
                      onRemove={() => handleRemoveImage('targetImage')}
                      isCompact={isCompact}
                    />
                  )}
                </div>

                {(state.isProcessing || state.mergedImage) && (
                  <ResultPreview 
                    image={state.mergedImage} 
                    isLoading={state.isProcessing} 
                  />
                )}
              </div>
            )}
          </div>
        </div>


        {/* Hidden File Inputs */}
        <input
          type="file"
          ref={faceImageInputRef}
          onChange={(e) => e.target.files?.[0] && handleFile(e.target.files[0], 'faceImage')}
          accept="image/*"
          className="hidden"
        />
        <input
          type="file"
          ref={targetImageInputRef}
          onChange={(e) => e.target.files?.[0] && handleFile(e.target.files[0], 'targetImage')}
          accept="image/*"
          className="hidden"
        />

        {/* Action Buttons */}
        {state.faceImage && state.targetImage && !state.showDownload && (
          <div className="fixed bottom-6 inset-x-0 flex justify-center">
            <div className="relative flex items-center justify-center space-x-4">
              {state.mergedImage ? (
                <button
                  onClick={handleSave}
                  className="px-6 py-3 bg-purple-500 text-white rounded-full shadow-lg inline-flex items-center justify-center hover:bg-purple-600 transition-colors"
                >
                  Save Result
                </button>
              ) : (
                <>
                  <span className="absolute inset-0 rounded-full bg-purple-400 opacity-75 animate-ping" />
                  <button
                    onClick={handleMerge}
                    disabled={state.isProcessing}
                    className={`relative px-6 py-3 bg-purple-500 text-white rounded-full shadow-lg inline-flex items-center justify-center transition-colors z-50 ${
                      state.isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-600'
                    }`}
                  >
                    {state.isProcessing ? (
                      <span className="flex items-center">
                        <Loader className="w-5 h-5 mr-2 animate-spin" />
                        Merging...
                      </span>
                    ) : (
                      <>
                        Merge Faces
                        <ChevronRight className="w-5 h-5 ml-2" />
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MergeFaces;
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Lock } from 'lucide-react'; // Add this import

export const MenuItem = ({ 
  icon: Icon, 
  label, 
  description, 
  to, 
  onClick, 
  isActive, 
  requiresAuth, 
  user,
  delay = 0 
}) => {
  const baseClasses = `flex items-center w-full px-4 py-3 text-sm transition-colors duration-200 ${
    isActive
      ? 'bg-purple-50 text-purple-700'
      : 'text-gray-700 hover:bg-gray-50 hover:text-purple-600'
  }`;

  const content = (
    <motion.div
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay }}
      className="flex items-center w-full"
    >
      {Icon && (
        <div className="flex-shrink-0">
          <Icon className={`w-5 h-5 ${isActive ? 'text-purple-500' : 'text-gray-400'}`} />
        </div>
      )}
      <div className="ml-3 flex-1">
        <p className="font-medium">{label}</p>
        {description && (
          <p className="text-xs text-gray-500 mt-0.5">{description}</p>
        )}
      </div>
      {requiresAuth && !user && (
        <Lock className="w-4 h-4 ml-2 text-gray-400" />
      )}
    </motion.div>
  );

  if (to) {
    const linkProps = requiresAuth && !user ? {
      to: "/auth",
      state: { returnUrl: to, message: `Please sign in to use ${label}` }
    } : { to };

    return (
      <Link {...linkProps} className={baseClasses} onClick={onClick}>
        {content}
      </Link>
    );
  }

  return (
    <button className={`${baseClasses} text-left`} onClick={onClick}>
      {content}
    </button>
  );
};
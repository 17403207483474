import React from 'react';
import { Helmet } from 'react-helmet';
import { Calendar, User, Clock, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

// First, install the syntax highlighter:
// npm install react-syntax-highlighter

const CustomLink = ({ href, children }) => {
  const isInternal = href.startsWith('/');
  if (isInternal) {
    return (
      <Link to={href} className="text-purple-600 hover:text-purple-700 font-medium">
        {children}
      </Link>
    );
  }
  return (
    <a 
      href={href} 
      target="_blank" 
      rel="noopener noreferrer"
      className="text-purple-600 hover:text-purple-700 font-medium"
    >
      {children}
    </a>
  );
};

const CustomCode = ({ node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <div className="rounded-lg overflow-hidden my-6">
      <SyntaxHighlighter
        style={atomDark}
        language={match[1]}
        PreTag="div"
        className="!bg-gray-800 !p-4"
        showLineNumbers={true}
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code 
      className="bg-gray-100 text-purple-600 px-2 py-1 rounded text-sm font-mono" 
      {...props}
    >
      {children}
    </code>
  );
};

const CustomBlockquote = ({ children }) => (
  <blockquote className="border-l-4 border-purple-500 pl-4 my-4 italic text-gray-700">
    {children}
  </blockquote>
);

const CustomTable = ({ children }) => (
  <div className="overflow-x-auto my-8 rounded-lg border border-gray-200">
    <table className="min-w-full divide-y divide-gray-200">
      {children}
    </table>
  </div>
);

const CustomTh = ({ children }) => (
  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    {children}
  </th>
);

const CustomTd = ({ children }) => (
  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
    {children}
  </td>
);

const UnderstandingImageFormats = () => {
  const metadata = {
    title: "Understanding Image Formats: JPG vs PNG vs WebP",
    date: "October 26, 2024",
    author: "Hamza",
    readTime: "5",
    category: "Education"
  };

  const content = `
# Understanding Image Formats: JPG vs PNG vs WebP

Choosing the right image format is crucial for balancing quality and performance in your digital projects. 
Let's explore the three most popular image formats and understand when to use each one.

## JPEG (JPG) Format

### When to Use JPG:

* **Photographs and complex images** with many colors
* When file size is a priority
* Web content where some quality loss is acceptable
* Social media images

> JPEG uses lossy compression, meaning it sacrifices some image quality to achieve smaller file sizes. 
It's particularly good at handling photographs and images with gradual color transitions.

## PNG Format

### When to Use PNG:

* **Images requiring transparency**
* Screenshots and images with text
* Logos and graphics with sharp edges
* When quality is more important than file size

PNG uses lossless compression, preserving image quality without any data loss. It supports transparency 
and handles sharp edges well, making it perfect for logos, graphics, and screenshots.

## WebP Format

### When to Use WebP:

* **Modern websites** and web applications
* When you want the best of both JPG and PNG
* Projects requiring both high quality and small file sizes
* Progressive web apps

WebP is a modern format developed by Google that offers both lossy and lossless compression. It provides 
better compression than both JPEG and PNG while maintaining similar or better quality.

## Format Comparison

| Feature | JPG | PNG | WebP |
|---------|-----|-----|------|
| Compression | Lossy | Lossless | Both |
| Transparency | No | Yes | Yes |
| File Size | Small | Large | Smallest |
| Best For | Photos | Graphics | Both |

## Implementation Example

\`\`\`html
<!-- Responsive image with WebP and fallback -->
<picture>
  <source srcset="image.webp" type="image/webp">
  <source srcset="image.jpg" type="image/jpeg">
  <img src="image.jpg" alt="Responsive image example">
</picture>
\`\`\`

## Key Considerations

1. **Content Type:** What kind of image are you working with?
2. **Quality Requirements:** How important is perfect quality?
3. **File Size:** Are you working with bandwidth constraints?
4. **Transparency:** Do you need transparent areas?
5. **Browser Support:** Which browsers need to be supported?

---

**Ready to convert your images?** Try our conversion tools:

- [Convert to JPG](/convert-to-jpg)
- [Convert to PNG](/convert-to-png)
- [Convert to WebP](/convert-to-webp)
`;

  return (
    <>
      <Helmet>
        <title>{metadata.title} - ilikeimg</title>
        <meta name="description" content="Learn about different image formats (JPG, PNG, WebP) and when to use each one for optimal results." />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link 
          to="/blog" 
          className="inline-flex items-center text-purple-600 hover:text-purple-700 mb-8 font-medium"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Blog
        </Link>

        <header className="mb-12">
          <div className="flex items-center space-x-4 mb-4">
            <span className="text-sm font-medium text-purple-600 bg-purple-50 px-3 py-1 rounded-full">
              {metadata.category}
            </span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {metadata.title}
          </h1>
          <div className="flex items-center text-sm text-gray-500 space-x-4">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              {metadata.date}
            </div>
            <div className="flex items-center">
              <User className="w-4 h-4 mr-1" />
              {metadata.author}
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {metadata.readTime} min read
            </div>
          </div>
        </header>

        <div className="prose prose-lg prose-purple max-w-none">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              a: ({ node, ...props }) => <CustomLink {...props} />,
              code: CustomCode,
              blockquote: CustomBlockquote,
              table: CustomTable,
              th: CustomTh,
              td: CustomTd,
              h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-8 mb-4" {...props} />,
              h2: ({node, ...props}) => <h2 className="text-2xl font-bold mt-8 mb-4" {...props} />,
              h3: ({node, ...props}) => <h3 className="text-xl font-bold mt-6 mb-3" {...props} />,
              p: ({node, ...props}) => <p className="mb-4 leading-relaxed text-gray-700" {...props} />,
              ul: ({node, ...props}) => <ul className="mb-4 ml-4 space-y-2" {...props} />,
              ol: ({node, ...props}) => <ol className="mb-4 ml-4 space-y-2 list-decimal" {...props} />,
              li: ({node, ...props}) => <li className="text-gray-700" {...props} />,
            }}
          >
            {content}
          </ReactMarkdown>
        </div>

        <div className="mt-12 p-6 bg-purple-50 rounded-lg">
          <h3 className="text-lg font-semibold text-purple-900 mb-2">
            Try Our Conversion Tools
          </h3>
          <p className="text-purple-700 mb-4">
            Ready to convert your images? Use our free tools:
          </p>
          <div className="flex flex-wrap gap-4">
            <Link 
              to="/convert-to-jpg"
              className="inline-block px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              Convert to JPG
            </Link>
            <Link 
              to="/convert-to-png"
              className="inline-block px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              Convert to PNG
            </Link>
            <Link 
              to="/convert-to-webp"
              className="inline-block px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              Convert to WebP
            </Link>
          </div>
        </div>
      </article>
    </>
  );
};

export default UnderstandingImageFormats;
import React, { useState, useEffect } from 'react';

const CropBox = ({ cropArea, imageSize, scaleFactor, onCropChange }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [startCrop, setStartCrop] = useState({ ...cropArea });

    // Helper to get coordinates from either mouse or touch event
    const getEventCoordinates = (e) => {
        if (e.touches) {
            return {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            };
        }
        return {
            x: e.clientX,
            y: e.clientY
        };
    };

    const handleStart = (e, isHandle = false) => {
        e.preventDefault();
        e.stopPropagation();
        
        const coords = getEventCoordinates(e);
        
        if (isHandle) {
            setIsResizing(true);
        } else {
            setIsDragging(true);
        }
        
        setStartPos(coords);
        setStartCrop({ ...cropArea });
    };

    const constrainCrop = (crop) => {
        const newCrop = { ...crop };
        
        // Ensure minimum dimensions
        newCrop.width = Math.max(20, newCrop.width);
        newCrop.height = Math.max(20, newCrop.height);

        // Ensure crop doesn't exceed image bounds
        if (newCrop.x < 0) {
            newCrop.x = 0;
        }
        if (newCrop.y < 0) {
            newCrop.y = 0;
        }
        if (newCrop.x + newCrop.width > imageSize.width) {
            newCrop.width = imageSize.width - newCrop.x;
        }
        if (newCrop.y + newCrop.height > imageSize.height) {
            newCrop.height = imageSize.height - newCrop.y;
        }
        
        return newCrop;
    };

    const handleMove = (e) => {
        if (!isDragging && !isResizing) return;
        e.preventDefault();
        
        const coords = getEventCoordinates(e);
        const dx = (coords.x - startPos.x) / scaleFactor;
        const dy = (coords.y - startPos.y) / scaleFactor;
        
        let newCrop = { ...startCrop };

        if (isDragging) {
            // Handle dragging
            newCrop.x = Math.min(
                Math.max(0, newCrop.x + dx),
                imageSize.width - newCrop.width
            );
            newCrop.y = Math.min(
                Math.max(0, newCrop.y + dy),
                imageSize.height - newCrop.height
            );
        } else if (isResizing) {
            // Handle resizing - completely free-form
            newCrop.width = Math.max(20, newCrop.width + dx);
            newCrop.height = Math.max(20, newCrop.height + dy);

            // Constrain to image boundaries
            if (newCrop.x + newCrop.width > imageSize.width) {
                newCrop.width = imageSize.width - newCrop.x;
            }
            if (newCrop.y + newCrop.height > imageSize.height) {
                newCrop.height = imageSize.height - newCrop.y;
            }
        }

        newCrop = constrainCrop(newCrop);
        onCropChange(newCrop);
    };

    const handleEnd = () => {
        setIsDragging(false);
        setIsResizing(false);
    };

    useEffect(() => {
        const handleGlobalMove = (e) => {
            if (isDragging || isResizing) {
                handleMove(e);
            }
        };

        const handleGlobalEnd = () => {
            if (isDragging || isResizing) {
                handleEnd();
            }
        };

        // Mouse events
        window.addEventListener('mousemove', handleGlobalMove);
        window.addEventListener('mouseup', handleGlobalEnd);
        
        // Touch events
        window.addEventListener('touchmove', handleGlobalMove, { passive: false });
        window.addEventListener('touchend', handleGlobalEnd);
        window.addEventListener('touchcancel', handleGlobalEnd);

        return () => {
            // Cleanup mouse events
            window.removeEventListener('mousemove', handleGlobalMove);
            window.removeEventListener('mouseup', handleGlobalEnd);
            
            // Cleanup touch events
            window.removeEventListener('touchmove', handleGlobalMove);
            window.removeEventListener('touchend', handleGlobalEnd);
            window.removeEventListener('touchcancel', handleGlobalEnd);
        };
    }, [isDragging, isResizing, startPos, startCrop, scaleFactor, imageSize]);

    return (
        <div
            style={{
                position: 'absolute',
                left: `${cropArea.x * scaleFactor}px`,
                top: `${cropArea.y * scaleFactor}px`,
                width: `${cropArea.width * scaleFactor}px`,
                height: `${cropArea.height * scaleFactor}px`,
                border: '2px solid white',
                boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
                cursor: isDragging ? 'move' : 'default',
                touchAction: 'none'
            }}
            onMouseDown={(e) => handleStart(e)}
            onTouchStart={(e) => handleStart(e)}
        >
            {/* Grid overlay */}
            <div className="absolute inset-0">
                {/* Vertical lines */}
                <div 
                    className="absolute left-1/3 top-0 bottom-0 border-l border-dotted border-white opacity-50"
                />
                <div 
                    className="absolute right-1/3 top-0 bottom-0 border-l border-dotted border-white opacity-50"
                />
                
                {/* Horizontal lines */}
                <div 
                    className="absolute top-1/3 left-0 right-0 border-t border-dotted border-white opacity-50"
                />
                <div 
                    className="absolute bottom-1/3 left-0 right-0 border-t border-dotted border-white opacity-50"
                />
            </div>

            {/* Corner resize handles */}
            <div
                className="absolute -bottom-2 -right-2 w-6 h-6 bg-white rounded-sm cursor-se-resize"
                style={{ 
                    border: '1px solid #666',
                    touchAction: 'none',
                    boxShadow: '0 0 3px rgba(0,0,0,0.3)'
                }}
                onMouseDown={(e) => handleStart(e, true)}
                onTouchStart={(e) => handleStart(e, true)}
            />

            {/* Visual feedback for touch interaction */}
            {(isDragging || isResizing) && (
                <div className="absolute inset-0 bg-purple-500 bg-opacity-20 pointer-events-none" />
            )}

            {/* Optional touch indicators for corners */}
            <div className="absolute -top-2 -left-2 w-4 h-4 border-2 border-white rounded-full pointer-events-none opacity-50" />
            <div className="absolute -top-2 -right-2 w-4 h-4 border-2 border-white rounded-full pointer-events-none opacity-50" />
            <div className="absolute -bottom-2 -left-2 w-4 h-4 border-2 border-white rounded-full pointer-events-none opacity-50" />
            <div className="absolute -bottom-2 -right-2 w-4 h-4 border-2 border-white rounded-full pointer-events-none opacity-50" />

            {/* Optional dimensions display */}
            <div 
                className="absolute -top-6 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-75 text-white text-xs px-2 py-1 rounded pointer-events-none"
                style={{ minWidth: '60px', textAlign: 'center' }}
            >
                {Math.round(cropArea.width)} × {Math.round(cropArea.height)}
            </div>
        </div>
    );
};

export default CropBox;
import React from 'react';
import { Helmet } from 'react-helmet';
import { Calendar, User, Clock, ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const ImageOptimizationTips = () => {
  const metadata = {
    title: "Tips for Optimizing Images for Web",
    date: "January 10, 2024",
    author: "Jane Smith",
    readTime: "4",
    category: "Tips & Tricks"
  };

  return (
    <>
      <Helmet>
        <title>{metadata.title} - ilikeimg Blog</title>
        <meta name="description" content="Learn essential tips and best practices for optimizing images for your website to improve loading times and user experience." />
      </Helmet>

      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link 
          to="/blog" 
          className="inline-flex items-center text-purple-600 hover:text-purple-700 mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Blog
        </Link>

        <header className="mb-12">
          <div className="flex items-center space-x-4 mb-4">
            <span className="text-sm font-medium text-purple-600 bg-purple-50 px-3 py-1 rounded-full">
              {metadata.category}
            </span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {metadata.title}
          </h1>
          <div className="flex items-center text-sm text-gray-500 space-x-4">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              {metadata.date}
            </div>
            <div className="flex items-center">
              <User className="w-4 h-4 mr-1" />
              {metadata.author}
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              {metadata.readTime} min read
            </div>
          </div>
        </header>

        <div className="prose prose-purple max-w-none">
          <h2>Why Image Optimization Matters</h2>
          <p>
            Web performance is crucial for user experience and SEO. Images often make up the largest portion of a webpage's size,
            making image optimization one of the most effective ways to improve your site's loading speed.
          </p>

          <h2>1. Choose the Right Format</h2>
          <ul>
            <li>Use JPEG for photographs and images with many colors</li>
            <li>Choose PNG for images that need transparency or have text</li>
            <li>Consider WebP with a JPEG/PNG fallback for broader browser support</li>
            <li>Use SVG for logos, icons, and simple illustrations</li>
          </ul>

          <h2>2. Size Images Appropriately</h2>
          <p>
            Never upload images larger than their display size. If your image container is 800px wide, 
            don't use a 2400px wide image. Resize images before uploading them to your server.
          </p>

          <h2>3. Implement Responsive Images</h2>
          <div className="bg-gray-50 p-4 rounded-md my-4">
            <pre className="text-sm">
              {`<img
  srcset="small.jpg 300w,
          medium.jpg 600w,
          large.jpg 900w"
  sizes="(max-width: 320px) 280px,
         (max-width: 640px) 580px,
         800px"
  src="large.jpg"
  alt="Responsive image example"
/>`}
            </pre>
          </div>

          <h2>4. Use Image Compression</h2>
          <p>
            Compress images before uploading them to reduce file size while maintaining acceptable quality.
            Tools like ilikeimg's compress feature can help you achieve this balance.
          </p>

          <h2>5. Implement Lazy Loading</h2>
          <p>
            Use the loading="lazy" attribute for images below the fold:
          </p>
          <pre className="bg-gray-50 p-4 rounded-md my-4">
            {'<img src="image.jpg" loading="lazy" alt="Lazy loaded image" />'}
          </pre>

          <h2>6. Optimize Alt Text</h2>
          <p>
            Always include descriptive alt text for accessibility and SEO:
          </p>
          <ul>
            <li>Make it descriptive but concise</li>
            <li>Include keywords when relevant</li>
            <li>Avoid phrases like "image of" or "picture of"</li>
          </ul>

          <h2>7. Consider Using Modern Formats</h2>
          <p>
            Modern image formats offer better compression:
          </p>
          <ul>
            <li>WebP for general use</li>
            <li>AVIF for next-generation compression</li>
            <li>Provide fallbacks for older browsers</li>
          </ul>

          <h2>8. Content Delivery Networks (CDNs)</h2>
          <p>
            Use a CDN to serve images from locations closer to your users and handle image optimization automatically.
          </p>

          <h2>9. Cache Control</h2>
          <p>
            Implement proper cache headers for images to reduce server load and improve loading times for returning visitors.
          </p>

          <h2>Measuring Success</h2>
          <p>
            Use these tools to measure your optimization efforts:
          </p>
          <ul>
            <li>Google PageSpeed Insights</li>
            <li>WebPageTest</li>
            <li>Browser Developer Tools</li>
            <li>Lighthouse</li>
          </ul>

        </div>

        <div className="mt-12 p-6 bg-purple-50 rounded-lg">
          <h3 className="text-lg font-semibold text-purple-900 mb-2">
            Optimize Your Images Now
          </h3>
          <p className="text-purple-700 mb-4">
            Use our free tools to optimize your images:
          </p>
          <div className="flex flex-wrap gap-4">
            <Link 
              to="/image-compress"
              className="inline-block px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              Compress Images
            </Link>
            <Link 
              to="/image-resize"
              className="inline-block px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              Resize Images
            </Link>
          </div>
        </div>
      </article>
    </>
  );
};

export default ImageOptimizationTips;
// src/services/PricingService.js

class PricingService {
    constructor() {
        this.prices = null;
        this.fetchPromise = null;
    }

    async getPricing() {
        if (this.prices) {
            return this.prices;
        }

        if (this.fetchPromise) {
            return this.fetchPromise;
        }

        try {
            this.fetchPromise = fetch(`${process.env.REACT_APP_API_URL}/payments/pricing`)
                .then(res => {
                    if (!res.ok) throw new Error('Failed to fetch pricing');
                    return res.json();
                })
                .then(data => {
                    this.prices = data;
                    return data;
                })
                .finally(() => {
                    this.fetchPromise = null;
                });

            return this.fetchPromise;
        } catch (error) {
            console.error('Error fetching pricing:', error);
            throw error;
        }
    }

    async getOperationCost(operation) {
        const pricing = await this.getPricing();
        return pricing?.credits?.costs?.[operation.toUpperCase()] || 0;
    }
}

export const pricingService = new PricingService();
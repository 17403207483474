// ConvertToPDF.js
import React from 'react';
import BaseConverter from './BaseConverter';

const ConvertToPDF = () => (
  <BaseConverter
    format="pdf"
    title="Convert to PDF"
    description="Convert your images to PDF format with professional quality."
  />
);
export default ConvertToPDF;

import React from 'react';
import { Helmet } from 'react-helmet';

const CookiePolicy = () => {
  return (
    <>
      <Helmet>
        <title>Cookie Policy - ilikeimg</title>
        <meta name="description" content="Cookie policy for ilikeimg - Learn about how we use cookies" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Cookie Policy</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">What Are Cookies</h2>
          <p className="text-gray-600 mb-4">
            Cookies are small text files that are stored on your computer when you visit our website.
            They help us provide you with a better experience by remembering your preferences.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">How We Use Cookies</h2>
          <p className="text-gray-600 mb-4">
            We use cookies to track anonymous usage statistics and improve our service.
            We do not use cookies to collect personally identifiable information.
          </p>

          {/* Add more sections as needed */}
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - ilikeimg</title>
        <meta name="description" content="Privacy policy for ilikeimg - Learn how we handle and protect your data" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">1. Information We Collect</h2>
          <p className="text-gray-600 mb-4">
            All image processing is done locally in your browser. We do not store or transmit your images to any server.
            We collect anonymous usage statistics through Google Analytics to improve our service.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">2. How We Use Your Information</h2>
          <p className="text-gray-600 mb-4">
            The anonymous usage data we collect helps us understand how our tools are being used and how we can improve them.
            We do not sell or share any personal information with third parties.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">3. Cookies</h2>
          <p className="text-gray-600 mb-4">
            We use cookies to remember your preferences and provide a better experience. 
            You can control cookie settings through your browser preferences.
          </p>

          {/* Add more sections as needed */}
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const DropdownMenu = ({ 
  isOpen, 
  onClose, 
  children, 
  width = "w-48", 
  align = "right-0",
  showOnHover = false
}) => (
  <AnimatePresence>
    {isOpen && (
      <>
        {/* Overlay for mobile/click events */}
        {!showOnHover && (
          <div 
            className="fixed inset-0 z-40 bg-black/5 backdrop-blur-sm sm:hidden"
            onClick={onClose}
          />
        )}
        
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          className={`absolute ${align} mt-2 ${width} rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50 overflow-hidden`}
          onMouseLeave={showOnHover ? onClose : undefined}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
          >
            {children}
          </motion.div>
        </motion.div>
      </>
    )}
  </AnimatePresence>
);
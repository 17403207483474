import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Upload, ChevronRight, Settings } from 'lucide-react';
import DownloadFile from './DownloadFile';

const MAX_PERCENTAGE = 200;
const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB in bytes

const CustomAlert = ({ message, variant = "error" }) => (
    <div className={`p-4 rounded-md ${variant === "error" ? "bg-red-50 text-red-800" : "bg-yellow-50 text-yellow-800"}`}>
        <p className="text-sm font-medium">{message}</p>
    </div>
);

const SettingsDrawer = ({ isOpen, onClose, imageInfo, resizeValues, onResizeChange, isResizing, error }) => {
    const handleInputChange = (type, value) => {
        if (isResizing) return;
        
        const aspectRatio = imageInfo.width / imageInfo.height;
        let newWidth, newHeight, newPercentage;
        let exceedsLimit = false;

        switch (type) {
            case 'width':
                newWidth = parseInt(value) || 1;
                newHeight = Math.round(newWidth / aspectRatio);
                newPercentage = Math.round((newWidth / imageInfo.width) * 100);
                exceedsLimit = newPercentage > MAX_PERCENTAGE;
                break;
            case 'height':
                newHeight = parseInt(value) || 1;
                newWidth = Math.round(newHeight * aspectRatio);
                newPercentage = Math.round((newHeight / imageInfo.height) * 100);
                exceedsLimit = newPercentage > MAX_PERCENTAGE;
                break;
            case 'percentage':
                newPercentage = parseInt(value) || 1;
                newWidth = Math.round((imageInfo.width * newPercentage) / 100);
                newHeight = Math.round((imageInfo.height * newPercentage) / 100);
                exceedsLimit = newPercentage > MAX_PERCENTAGE;
                break;
            default:
                return;
        }

        // Ensure values don't go below 1
        newWidth = Math.max(1, newWidth);
        newHeight = Math.max(1, newHeight);
        newPercentage = Math.max(1, newPercentage);

        onResizeChange({ 
            width: newWidth, 
            height: newHeight, 
            percentage: newPercentage,
            error: exceedsLimit ? `Cannot resize more than ${MAX_PERCENTAGE}%` : null
        });
    };

    return (
        <div className={`fixed top-0 right-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-30 h-full ${isOpen ? 'translate-x-0' : 'translate-x-full'} sm:translate-x-0 sm:relative sm:transform-none sm:z-0`}>
            <div className="p-4 h-full flex flex-col overflow-y-auto">
                <div className="mb-4">
                    <h3 className="text-lg font-medium">Resize Settings</h3>
                </div>
                
                <div className="mb-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Original Image</h4>
                    <p className="text-sm text-gray-600">Size: {imageInfo.fileSize}</p>
                    <p className="text-sm text-gray-600">Dimensions: {imageInfo.width} × {imageInfo.height}px</p>
                </div>

                {error && (
                    <div className="mb-4">
                        <CustomAlert message={error} />
                    </div>
                )}

                <div className="mb-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">New Dimensions</h4>
                    <div className="space-y-3">
                        <div>
                            <label className="block text-sm text-gray-600 mb-1">Width (px)</label>
                            <input
                                type="number"
                                min="1"
                                value={resizeValues.width}
                                onChange={(e) => handleInputChange('width', e.target.value)}
                                className={`w-full px-3 py-2 border rounded-md ${error ? 'border-red-500' : ''}`}
                                disabled={isResizing}
                            />
                        </div>
                        <div>
                            <label className="block text-sm text-gray-600 mb-1">Height (px)</label>
                            <input
                                type="number"
                                min="1"
                                value={resizeValues.height}
                                onChange={(e) => handleInputChange('height', e.target.value)}
                                className={`w-full px-3 py-2 border rounded-md ${error ? 'border-red-500' : ''}`}
                                disabled={isResizing}
                            />
                        </div>
                        <div>
                            <label className="block text-sm text-gray-600 mb-1">Scale (%)</label>
                            <input
                                type="number"
                                min="1"
                                max={MAX_PERCENTAGE}
                                value={resizeValues.percentage}
                                onChange={(e) => handleInputChange('percentage', e.target.value)}
                                className={`w-full px-3 py-2 border rounded-md ${error ? 'border-red-500' : ''}`}
                                disabled={isResizing}
                            />
                        </div>
                    </div>
                </div>

                {imageInfo.resizedSize && (
                    <div className="mb-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">Resized Image</h4>
                        <p className="text-sm text-gray-600">Estimated size: {imageInfo.resizedSize}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const ResizeImage = () => {
    const [state, setState] = useState({
        image: null,
        drawerOpen: false,
        imageInfo: {
            width: 0,
            height: 0,
            fileSize: '',
            resizedSize: ''
        },
        resizeValues: {
            width: 0,
            height: 0,
            percentage: 100,
            error: null
        },
        isResizing: false,
        showDownload: false,
        downloadFiles: []
    });
    
    const [isDragging, setIsDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const dropZoneRef = useRef(null);
    const originalFile = useRef(null);

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const resetState = () => {
        setState({
            image: null,
            drawerOpen: false,
            imageInfo: {
                width: 0,
                height: 0,
                fileSize: '',
                resizedSize: ''
            },
            resizeValues: {
                width: 0,
                height: 0,
                percentage: 100,
                error: null
            },
            isResizing: false,
            showDownload: false,
            downloadFiles: []
        });
        originalFile.current = null;
        setDragCounter(0);
        setIsDragging(false);
        setError(null);
    };

    const toggleDrawer = () => {
        setState(prevState => ({
            ...prevState,
            drawerOpen: !prevState.drawerOpen
        }));
    };

    const handleResize = () => {
      setState(prevState => ({ ...prevState, isResizing: true }));
      
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      
      img.onload = () => {
          canvas.width = state.resizeValues.width;
          canvas.height = state.resizeValues.height;
          
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          
          canvas.toBlob((blob) => {
              const fileExtension = originalFile.current.type.split('/')[1];
              const timestamp = new Date().toISOString().replace(/[-:]/g, '').split('.')[0];
              const fileName = `ilikeimg.com_${timestamp}.${fileExtension}`;
              
              setState(prevState => ({
                  ...prevState,
                  showDownload: true,
                  drawerOpen: false,
                  isResizing: false,
                  downloadFiles: [{
                      name: fileName,
                      blob: blob
                  }]
              }));
          }, originalFile.current.type, 0.95);
      };
      
      img.src = state.image;
  };

    const isValidImageFile = (file) => {
        const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        return validTypes.includes(file.type);
    };

    const handleFile = (file) => {
        setError(null);

        if (!isValidImageFile(file)) {
            setError('Please upload a valid image file (JPEG, PNG, GIF, or WebP)');
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            setError(`File size exceeds ${formatFileSize(MAX_FILE_SIZE)} limit`);
            return;
        }

        originalFile.current = file;
        const reader = new FileReader();
        
        reader.onload = (e) => {
            const img = new Image();
            
            img.onload = () => {
                const imageInfo = {
                    width: img.width,
                    height: img.height,
                    fileSize: formatFileSize(file.size),
                    resizedSize: formatFileSize(file.size)
                };
                
                setState(prevState => ({
                    ...prevState,
                    image: e.target.result,
                    imageInfo,
                    resizeValues: {
                        width: img.width,
                        height: img.height,
                        percentage: 100,
                        error: null
                    },
                    drawerOpen: window.innerWidth >= 640,
                    showDownload: false,
                    downloadFiles: []
                }));
            };
            
            img.src = e.target.result;
        };
        
        reader.readAsDataURL(file);
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            handleFile(event.target.files[0]);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(prev => prev + 1);
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(prev => prev - 1);
        if (dragCounter === 1) {
            setIsDragging(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        setDragCounter(0);

        const items = e.dataTransfer.items;
        const files = e.dataTransfer.files;

        if (items && items.length > 0) {
            if (items[0].webkitGetAsEntry && items[0].webkitGetAsEntry().isDirectory) {
                alert('Folder upload is not supported. Please drop a single image file.');
                return;
            }
        }

        if (files && files.length > 0) {
            if (files.length > 1) {
                alert('Please drop only one image file at a time.');
                return;
            }
            handleFile(files[0]);
        }
    };

    const handleResizeChange = (values) => {
        setState(prevState => ({
            ...prevState,
            resizeValues: values,
            imageInfo: {
                ...prevState.imageInfo,
                resizedSize: formatFileSize((values.width * values.height * 4) / 8) // Rough estimate
            }
        }));
    };

    useEffect(() => {
        const dropZone = dropZoneRef.current;
        if (!dropZone) return;

        dropZone.addEventListener('dragenter', handleDragEnter);
        dropZone.addEventListener('dragleave', handleDragLeave);
        dropZone.addEventListener('dragover', handleDragOver);
        dropZone.addEventListener('drop', handleDrop);

        return () => {
            dropZone.removeEventListener('dragenter', handleDragEnter);
            dropZone.removeEventListener('dragleave', handleDragLeave);
            dropZone.removeEventListener('dragover', handleDragOver);
            dropZone.removeEventListener('drop', handleDrop);
        };
    }, [dragCounter]);

    return (
        <>
            <Helmet>
                <title>Resize Images Online - Free Tool</title>
                <meta name="description" content="Easily resize your images online with our free tool. Change dimensions while maintaining aspect ratio. No registration required." />
            </Helmet>
            
            <div className="min-h-screen flex flex-col">
                {!state.image && (
                    <div className="text-center px-4 py-4">
                        <h2 className="text-3xl font-bold text-gray-900 mb-2">
                            Resize Image
                        </h2>
                        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                            Easily resize your images online while maintaining aspect ratio. Maximum resize: {MAX_PERCENTAGE}%
                        </p>
                    </div>
                )}

                {error && (
                    <div className="mx-4 mb-4">
                        <CustomAlert message={error} />
                    </div>
                )}
                
                <div className="flex flex-grow relative">
                    <div className="flex-grow p-4 sm:p-6 overflow-y-auto">
                        {!state.image ? (
                            <div className="flex-grow flex justify-center items-start pt-4">
                                <div
                                    ref={dropZoneRef}
                                    className={`w-full max-w-3xl p-8 sm:p-12 flex flex-col items-center justify-center border-2 border-dashed rounded-lg transition-colors ${
                                      isDragging ? 'border-purple-500 bg-purple-50' : 'border-gray-300'
                                    }`}
                                >
                                    <div className="text-center">
                                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">Drag & drop an image here</h3>
                                        <p className="mt-1 text-sm text-gray-500">or</p>
                                        <button 
                                            onClick={() => fileInputRef.current.click()}
                                            className="mt-2 px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 text-sm sm:text-base inline-flex items-center"
                                        >
                                            Browse Files
                                        </button>
                                        <p className="mt-2 text-xs text-gray-500">
                                            Supported formats: JPEG, PNG, GIF, WebP (Max {formatFileSize(MAX_FILE_SIZE)})
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : state.showDownload ? (
                            <DownloadFile
                                files={state.downloadFiles}
                                onBack={resetState}
                                toolName="Resize Image"
                                toolIdentifier="image-resize"
                            />
                        ) : (
                            <div className="flex-grow flex justify-center items-center">
                                <div className="max-w-md">
                                    <img 
                                        src={state.image} 
                                        alt="Preview" 
                                        className="max-w-full h-auto rounded-lg shadow-lg"
                                        style={{ maxHeight: '70vh' }}
                                    />
                                    <div className="mt-4 text-center text-sm text-gray-500">
                                        {state.imageInfo.width} × {state.imageInfo.height}px
                                    </div>
                                </div>
                            </div>
                        )}

                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/jpeg,image/png,image/gif,image/webp"
                            className="hidden"
                        />
                    </div>

                    {state.image && !state.showDownload && (
                        <div className="sm:w-64 flex-shrink-0">
                            <SettingsDrawer
                                isOpen={state.drawerOpen}
                                onClose={toggleDrawer}
                                imageInfo={state.imageInfo}
                                resizeValues={state.resizeValues}
                                onResizeChange={handleResizeChange}
                                isResizing={state.isResizing}
                                error={state.resizeValues.error}
                            />
                        </div>
                    )}
                </div>

                {state.image && !state.showDownload && (
                    <>
                        <button
                            onClick={toggleDrawer}
                            className={`fixed right-0 top-1/2 transform -translate-y-1/2 bg-purple-500 text-white p-2 rounded-l-md shadow-md sm:hidden z-40 transition-colors ${state.drawerOpen ? 'bg-purple-600' : ''}`}
                            disabled={state.isResizing}
                            aria-label="Toggle settings"
                        >
                            <Settings className="w-6 h-6" />
                        </button>
                        <button
                            onClick={handleResize}
                            className={`fixed bottom-6 right-6 bg-purple-500 text-white px-6 py-3 rounded-full shadow-lg inline-flex items-center justify-center transition-colors z-50 ${
                                state.isResizing || state.resizeValues.error ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-600'
                            }`}
                            disabled={state.isResizing || state.resizeValues.error}
                        >
                            {state.isResizing ? (
                                <span className="inline-flex items-center">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Resizing...
                                </span>
                            ) : (
                                <>
                                    Resize
                                    <ChevronRight className="w-5 h-5 ml-2" />
                                </>
                            )}
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

export default ResizeImage;
import React from 'react';
import BaseConverter from './BaseConverter';

const ConvertToJPG = () => (
  <BaseConverter
    format="jpg"
    title="Convert to JPG"
    description="Convert your images to JPG format efficiently and securely."
  />
);

export default ConvertToJPG;

import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const FAQItem = ({ question, answer, isOpen, onToggle, index }) => (
    <div className="border-b border-gray-100 last:border-0">
        <button
            onClick={() => onToggle(index)}
            className="w-full py-5 px-6 flex items-center justify-between text-left hover:bg-gray-50 transition-colors rounded-lg"
        >
            <h3 className="text-lg font-medium text-gray-900 pr-8">{question}</h3>
            <motion.div
                animate={{ rotate: isOpen ? 180 : 0 }}
                transition={{ duration: 0.2 }}
            >
                <ChevronDown className="w-5 h-5 text-gray-500" />
            </motion.div>
        </button>
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                >
                    <div className="px-6 pb-5 text-gray-600">
                        {answer}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    </div>
);

const FAQSection = () => {
    const [openIndex, setOpenIndex] = useState(0);

    const faqs = [
        {
            question: "What file formats can I upscale?",
            answer: "Our AI upscaling tool supports JPEG, PNG, and WebP formats. For best results, we recommend using high-quality source images with minimal compression artifacts."
        },
        {
            question: "What are the image size limits?",
            answer: "You can upload images up to 6MB in size. The maximum input dimension is 2048x2048 pixels, which can be upscaled to 8192x8192 pixels using our AI technology."
        },
     
        {
            question: "Do you store the uploaded images?",
            answer: "No, we prioritize your privacy and security. Images are processed in real-time and automatically deleted after processing. We never store or retain your images on our servers."
        },
        {
            question: "How long does processing take?",
            answer: "Most images are processed within 15-30 seconds. Processing time may vary depending on the image size and server load. Larger images might take slightly longer to process."
        },
        {
            question: "What resolution can I achieve?",
            answer: "Our AI technology increases your image resolution by 4x in each dimension. For example, a 1000x1000 pixel image will be upscaled to 4000x4000 pixels while maintaining quality and sharpness."
        }
      
   
    ];

    return (
        <div className="divide-y divide-gray-100">
            {faqs.map((faq, index) => (
                <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={index === openIndex}
                    onToggle={(i) => setOpenIndex(i === openIndex ? -1 : i)}
                    index={index}
                />
            ))}
        </div>
    );
};

export default FAQSection;
import React, { useState, useEffect,useContext } from 'react';
import { Download, ChevronLeft, AlertCircle, Loader, Star } from 'lucide-react';
import JSZip from 'jszip';
import { trackFileDownload, trackButtonClick } from '../utils/analytics';
import { AuthContext } from '../contexts/AuthContext';

const MAX_ZIP_SIZE = 500 * 1024 * 1024; // 500MB
const MAX_SINGLE_FILE_SIZE = 100 * 1024 * 1024; // 100MB

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};


const DownloadFile = ({ files, onBack, toolName, toolIdentifier, isLoading }) => {
  const { user } = useContext(AuthContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [error, setError] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [hoveredStar, setHoveredStar] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackError, setFeedbackError] = useState(null);
  
  const isMultiple = files?.length > 1;
  const totalSize = files?.reduce((sum, file) => sum + file.blob.size, 0) || 0;

  useEffect(() => {
    let timer;
    if (isWaiting) {
      timer = setTimeout(() => setIsWaiting(false), 3000);
    }
    return () => clearTimeout(timer);
  }, [isWaiting]);

  const validateFiles = () => {
    if (!files || files.length === 0) return null;
    
    if (isMultiple && totalSize > MAX_ZIP_SIZE) {
      return `Total size (${formatFileSize(totalSize)}) exceeds maximum limit of ${formatFileSize(MAX_ZIP_SIZE)}`;
    }
    
    const largeFile = files.find(file => file.blob.size > MAX_SINGLE_FILE_SIZE);
    if (largeFile) {
      return `File "${largeFile.name}" (${formatFileSize(largeFile.blob.size)}) exceeds maximum limit of ${formatFileSize(MAX_SINGLE_FILE_SIZE)}`;
    }
    
    return null;
  };

  const handleDownload = async (e) => {
    e.preventDefault();
    setError(null);
    setIsDownloading(true);
    
    const validationError = validateFiles();
    if (validationError) {
      setError(validationError);
      setIsDownloading(false);
      return;
    }

    try {
      trackFileDownload(files.length, totalSize);

      if (files.length === 1) {
        const file = files[0];
        const url = URL.createObjectURL(file.blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        trackButtonClick('download-single', `Download ${file.name}`, {
          file_size: file.blob.size,
          file_name: file.name,
          tool_name: toolName
        });
      } else {
        const zip = new JSZip();
        
        for (const file of files) {
          zip.file(file.name, file.blob);
        }

        const content = await zip.generateAsync({ 
          type: "blob",
          compression: "DEFLATE",
          compressionOptions: { level: 6 }
        });

        const zipFileName = `ilikeimg.com_${toolIdentifier}_${new Date().toISOString().replace(/[-:]/g, '').replace('T', '_').slice(0, -5)}.zip`;
        
        const url = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = url;
        link.download = zipFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        trackButtonClick('download-zip', `Download ${files.length} files`, {
          file_count: files.length,
          total_size: totalSize,
          zip_name: zipFileName,
          compressed_size: content.size,
          tool_name: toolName
        });
      }

      setIsWaiting(true);
    } catch (error) {
      console.error("Download failed", error);
      setError(`Download failed: ${error.message}`);
      trackButtonClick('download-error', 'Download Error', {
        error_message: error.message,
        file_count: files.length,
        tool_name: toolName
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedbackError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/feedback`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          ...(user?.token && { 'Authorization': `Bearer ${user.token}` })
        },
        body: JSON.stringify({
          tool_id: toolIdentifier,
          rating,
          comment,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        setFeedbackSubmitted(true);
        trackButtonClick('feedback-submitted', `Feedback for ${toolName}`, {
          is_guest: !user,
          rating,
          has_comment: Boolean(comment)
        });
      } else {
        const errorData = await response.json();
        setFeedbackError(errorData.error || 'Failed to submit feedback');
        console.error('Feedback submission failed:', errorData);
      }
    } catch (error) {
      console.error('Failed to submit feedback:', error);
      setFeedbackError('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-180px)] max-w-md mx-auto px-4">
        <div className="relative">
          <Loader className="w-12 h-12 text-purple-500 animate-spin" />
          <div className="mt-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              Converting your {isMultiple ? 'files' : 'file'}...
            </h2>
            <p className="text-gray-500">
              Please wait while we process your {isMultiple ? 'files' : 'file'}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-[calc(100vh-180px)] max-w-xl mx-auto px-4 py-8">
      <div className="flex-grow flex flex-col items-center justify-center text-center">
        {/* Success Icon */}
        <div className="mb-8">
          <div className="bg-purple-50 w-20 h-20 rounded-full mx-auto flex items-center justify-center">
            <Download className="w-10 h-10 text-purple-500" />
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-4 mb-8">
          <h2 className="text-3xl font-bold text-gray-800">
            Your {isMultiple ? 'files are' : 'file is'} ready!
          </h2>
          
          <p className="text-gray-600 text-lg">
            {isMultiple 
              ? `${files.length} files have been converted successfully.` 
              : 'Your file has been converted successfully.'}
          </p>

          <p className="text-sm text-gray-500">
            Total size: {formatFileSize(totalSize)}
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg text-red-700 flex items-start max-w-md">
            <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5" />
            <span>{error}</span>
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex items-center justify-center w-full max-w-md space-x-4 mb-8">
          <button
            onClick={onBack}
            className="w-12 h-12 bg-purple-500 text-white rounded-full hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 flex items-center justify-center transition-colors duration-200"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>

          <button
            onClick={handleDownload}
            disabled={isDownloading || isWaiting || error || !files?.length}
            className="flex-grow py-3 bg-purple-500 text-white rounded-xl hover:bg-purple-600 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 flex items-center justify-center space-x-2"
          >
            {isDownloading ? (
              <>
                <Loader className="w-5 h-5 animate-spin" />
                <span>Downloading...</span>
              </>
            ) : (
              <>
                <Download className="w-5 h-5" />
                <span>Download {isMultiple ? 'Files' : 'File'}</span>
              </>
            )}
          </button>
        </div>

        {/* Download Hint */}
        {isWaiting && (
          <p className="text-sm text-gray-500 mb-8">
            Download should start automatically. If it doesn't,{' '}
            <button 
              onClick={handleDownload}
              className="text-purple-500 hover:text-purple-600 underline focus:outline-none"
            >
              click here
            </button>
          </p>
        )}

        {/* Feedback Section */}
        {!feedbackSubmitted ? (
          <div className="w-full max-w-md pt-8 border-t border-gray-100">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              How was your experience?
            </h3>
            
            <div className="flex justify-center gap-2 mb-6">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  type="button"
                  onClick={() => setRating(star)}
                  onMouseEnter={() => setHoveredStar(star)}
                  onMouseLeave={() => setHoveredStar(0)}
                  className="transform transition-transform hover:scale-110"
                >
                  <Star
                    className={`w-8 h-8 transition-colors ${
                      star <= (hoveredStar || rating)
                        ? 'fill-yellow-400 text-yellow-400'
                        : 'text-gray-200'
                    }`}
                  />
                </button>
              ))}
            </div>

            {rating > 0 && (
              <form onSubmit={handleFeedbackSubmit} className="space-y-4">
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Share your thoughts (optional)"
                  className="w-full px-4 py-3 rounded-xl border-gray-200 focus:border-purple-500 focus:ring-purple-500 resize-none transition-all duration-200"
                  rows="3"
                />
                
                {feedbackError && (
                  <div className="text-red-600 text-sm">
                    {feedbackError}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-gray-900 text-white px-6 py-3 rounded-xl hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <Loader className="w-5 h-5 animate-spin mr-2" />
                      <span>Submitting...</span>
                    </span>
                  ) : (
                    'Submit Feedback'
                  )}
                </button>

                {!user && (
                  <p className="text-sm text-gray-500 text-center">
                    Submitting as guest. 
                    <a href="/auth" className="text-purple-500 hover:text-purple-600 ml-1">
                      Sign in
                    </a>
                    {' '}to track your feedback and get more features.
                  </p>
                )}
              </form>
            )}
          </div>
        ) : (
          <div className="w-full max-w-md pt-8 border-t border-gray-100 text-center">
            <svg 
              className="w-12 h-12 text-green-500 mx-auto mb-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Thanks for your feedback!
            </h3>
            {!user && (
              <p className="text-sm text-gray-600">
                Create an account to track your conversions and get more features.{' '}
                <a href="/auth" className="text-purple-500 hover:text-purple-600">
                  Sign up now
                </a>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadFile;
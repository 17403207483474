import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Upload, ChevronRight, Settings } from 'lucide-react';
import ImageComparisonSlider from './ImageComparisonSlider';
import DownloadFile from './DownloadFile';

const SettingsDrawer = ({ isOpen, onClose, compressionLevel, onCompressionLevelChange, fileSize, compressedSize, isCompressing }) => {
    return (
        <div className={`fixed top-0 right-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-30 h-full ${isOpen ? 'translate-x-0' : 'translate-x-full'} sm:translate-x-0 sm:relative sm:transform-none sm:z-0`}>
            <div className="p-4 h-full flex flex-col overflow-y-auto">
                <div className="mb-4">
                    <h3 className="text-lg font-medium">Compression Settings</h3>
                </div>
                <div className="mb-4">
                    <label htmlFor="compression-level" className="block text-sm font-medium text-gray-700 mb-1">Compression Level</label>
                    <input
                        id="compression-level"
                        type="range"
                        min="0"
                        max="100"
                        value={compressionLevel}
                        onChange={(e) => onCompressionLevelChange(Number(e.target.value))}
                        className="w-full"
                        disabled={isCompressing}
                    />
                    <span className="text-sm text-gray-600">{compressionLevel}%</span>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">File Information</label>
                    <p className="text-sm text-gray-600">Original size: {fileSize}</p>
                    <p className="text-sm text-gray-600">Compressed size: {compressedSize}</p>
                </div>
            </div>
        </div>
    );
};

const CompressImage = () => {
    const [state, setState] = useState({
        image: null,
        compressionLevel: 50,
        drawerOpen: false, // Initially closed on mobile
        fileSize: '',
        compressedSize: '',
        compressedImage: null,
        isCompressing: false,
        showDownload: false,
    });
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const dropZoneRef = useRef(null);
    const workerRef = useRef(null);

    useEffect(() => {
        workerRef.current = new Worker(new URL('./compressionWorker.js', import.meta.url));
        workerRef.current.onmessage = (e) => {
            const { compressedImage, compressedSize } = e.data;
            setState(prevState => ({
                ...prevState,
                compressedImage,
                compressedSize,
                isCompressing: false,
            }));
        };

        return () => {
            workerRef.current.terminate();
        };
    }, []);

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleFile = async (file) => {
        const formattedFileSize = formatFileSize(file.size);
        const reader = new FileReader();
        reader.onload = (e) => {
            setState(prevState => ({
                ...prevState,
                image: e.target.result,
                fileSize: formattedFileSize,
                drawerOpen: window.innerWidth >= 640, // Only open drawer by default on desktop
                showDownload: false,
            }));
        };
        reader.readAsDataURL(file);

        // Perform initial compression
        compressImage(file);
    };

    const compressImage = (file) => {
        setState(prevState => ({ ...prevState, isCompressing: true }));
        workerRef.current.postMessage({
            file,
            options: {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                initialQuality: state.compressionLevel / 100,
            }
        });
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            handleFile(event.target.files[0]);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = e.dataTransfer.files;
        if (files && files.length > 0) {
            handleFile(files[0]);
        }
    };

    useEffect(() => {
        const dropZone = dropZoneRef.current;
        if (!dropZone) return;

        dropZone.addEventListener('dragenter', handleDragEnter);
        dropZone.addEventListener('dragleave', handleDragLeave);
        dropZone.addEventListener('dragover', handleDragOver);
        dropZone.addEventListener('drop', handleDrop);

        return () => {
            dropZone.removeEventListener('dragenter', handleDragEnter);
            dropZone.removeEventListener('dragleave', handleDragLeave);
            dropZone.removeEventListener('dragover', handleDragOver);
            dropZone.removeEventListener('drop', handleDrop);
        };
    }, []);

    const handleCompressionLevelChange = (value) => {
        setState(prevState => ({
            ...prevState,
            compressionLevel: value
        }));
        if (state.image) {
            const file = dataURItoBlob(state.image);
            compressImage(file);
        }
    };

    const handleCompress = () => {
        const timestamp = new Date().toISOString().replace(/[-:]/g, '').split('.')[0];
        const fileExtension = state.image.split(';')[0].split('/')[1];
        const fileName = `ilikeimg.com_${timestamp}.${fileExtension}`;
        setState(prevState => ({
            ...prevState,
            showDownload: true,
            drawerOpen: false,
            compressedFileName: fileName,
        }));
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const resetCompression = () => {
        setState({
            image: null,
            compressionLevel: 50,
            drawerOpen: false,
            fileSize: '',
            compressedSize: '',
            compressedImage: null,
            isCompressing: false,
            showDownload: false,
        });
    };

    const toggleDrawer = () => {
        setState(prevState => ({
            ...prevState,
            drawerOpen: !prevState.drawerOpen
        }));
    };
    
    return (
        <>
            <Helmet>
                <title>Compress Images Online - Free Tool | ilikeimg</title>
                <meta name="description" content="Easily compress your images online with our free tool. Adjust compression level and reduce file size. No registration required." />
                <link rel="canonical" href="https://ilikeimg.com/image-compress" />
                <meta property="og:title" content="Compress Images Online - Free Tool | ilikeimg" />
                <meta property="og:description" content="Easily compress your images online with our free tool. Adjust compression level and reduce file size. No registration required." />
                <meta property="og:url" content="https://ilikeimg.com/image-compress" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Compress Images Online - Free Tool | ilikeimg" />
                <meta name="twitter:description" content="Easily compress your images online with our free tool. Adjust compression level and reduce file size. No registration required." />
            </Helmet>
            <div className="min-h-screen flex flex-col">
            {!state.image && (
    <div className="text-center px-4 py-4">
        <h2 className="text-3xl font-bold text-gray-900 mb-2">
            Compress Image
        </h2>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Easily compress and reduce the file size of your images online for free.
        </p>
    </div>
)}
                
                <div className="flex flex-grow relative">
                    <div className="flex-grow p-4 sm:p-6 overflow-y-auto">
                        {!state.image ? (
                            <div className="flex-grow flex justify-center items-start pt-4">
                                <div
                                    ref={dropZoneRef}
                                    className={`w-full max-w-3xl p-8 sm:p-12 flex flex-col items-center justify-center border-2 border-dashed rounded-lg transition-colors ${
                                        isDragging ? 'border-purple-500 bg-purple-50' : 'border-gray-300'
                                    }`}
                                >
                                    <div className="text-center">
                                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">Drag & drop an image here</h3>
                                        <p className="mt-1 text-sm text-gray-500">or</p>
                                        <button 
                                            onClick={() => fileInputRef.current.click()}
                                            className="mt-2 px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 text-sm sm:text-base inline-flex items-center"
                                        >
                                            Browse Files
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : state.showDownload ? (
                            <DownloadFile
                                files={[{ name: state.compressedFileName, blob: dataURItoBlob(state.compressedImage) }]}
                                onBack={resetCompression}
                                toolName="Compress Image"
                                toolIdentifier="image-compress"
                            />
                        ) : (
                            <div className="flex-grow flex justify-center items-center">
                                <ImageComparisonSlider
                                    originalImage={state.image}
                                    compressedImage={state.compressedImage}
                                    isCompressing={state.isCompressing}
                                />
                            </div>
                        )}

                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/*"
                            className="hidden"
                        />
                    </div>

                    {state.image && !state.showDownload && (
                        <div className="sm:w-64 flex-shrink-0">
                            <SettingsDrawer
                                isOpen={state.drawerOpen}
                                onClose={toggleDrawer}
                                compressionLevel={state.compressionLevel}
                                onCompressionLevelChange={handleCompressionLevelChange}
                                fileSize={state.fileSize}
                                compressedSize={state.compressedSize}
                                isCompressing={state.isCompressing}
                            />
                        </div>
                    )}
                </div>
                
                {state.image && !state.showDownload && (
                    <>
                        <button
                            onClick={toggleDrawer}
                            className={`fixed right-0 top-1/2 transform -translate-y-1/2 bg-purple-500 text-white p-2 rounded-l-md shadow-md sm:hidden z-40 transition-colors ${state.drawerOpen ? 'bg-purple-600' : ''}`}
                            disabled={state.isCompressing}
                        >
                            <Settings className="w-6 h-6" />
                        </button>
                        <button
                            onClick={handleCompress}
                            className="fixed bottom-6 right-6 bg-purple-500 text-white px-6 py-3 rounded-full shadow-lg inline-flex items-center justify-center disabled:opacity-50 hover:bg-purple-600 transition-colors z-50"
                            disabled={state.isCompressing}
                        >
                            Compress
                            <ChevronRight className="w-5 h-5 ml-2" />
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

export default CompressImage;
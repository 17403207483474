import React from 'react';
import ImageComparisonSlider from './ImageComparisonSlider';

const BeforeAfterSection = () => (
    <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                    See the Power of AI
                </span>
            </h2>
            <div className="lg:grid lg:grid-cols-2 lg:gap-12 items-center">
                {/* Image Comparison */}
                <div className="mb-8 lg:mb-0">
                    <div className="aspect-[4/3] rounded-xl overflow-hidden">
                        <div className="w-full h-full">
                            <ImageComparisonSlider
                                originalImage="/samples/upscale-before-sample.jpeg"
                                compressedImage="/samples/upscale-after-sample.jpeg"
                            />
                        </div>
                    </div>
                </div>

                {/* Features List */}
                <div className="space-y-6">
                    <div className="bg-gray-50 rounded-xl p-6">
                        <h3 className="text-xl font-semibold text-gray-900 mb-4">
                            Advanced AI Enhancement
                        </h3>
                        <ul className="space-y-4">
                            <li className="flex items-center space-x-3">
                                <div className="w-8 h-8 rounded-lg bg-purple-100 flex items-center justify-center flex-shrink-0">
                                    <span className="text-purple-600 font-semibold">4x</span>
                                </div>
                                <span className="text-gray-600">Resolution Enhancement</span>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center flex-shrink-0">
                                    <span className="text-blue-600 font-semibold">HD</span>
                                </div>
                                <span className="text-gray-600">Incredible Detail Preservation</span>
                            </li>
                            <li className="flex items-center space-x-3">
                                <div className="w-8 h-8 rounded-lg bg-green-100 flex items-center justify-center flex-shrink-0">
                                    <span className="text-green-600 font-semibold">AI</span>
                                </div>
                                <span className="text-gray-600">Smart Detail Recovery</span>
                            </li>
                        </ul>
                    </div>

                    {/* Quick Stats */}
                    <div className="grid grid-cols-2 gap-4">
                        <div className="bg-gray-50 rounded-xl p-4">
                            <div className="text-sm text-gray-500">Processing Time</div>
                            <div className="font-semibold text-gray-900">~7 seconds</div>
                        </div>
                        <div className="bg-gray-50 rounded-xl p-4">
                            <div className="text-sm text-gray-500">Success Rate</div>
                            <div className="font-semibold text-gray-900">99.9%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default BeforeAfterSection;
import React, { useState } from 'react';
import { Upload, FileText, Plus } from 'lucide-react';
import { validateFiles, getSupportedMimeTypes } from '../utils/fileValidation';
import ErrorAlert from './ErrorAlert';
import { trackFileUpload } from '../utils/analytics';
import { formatFileSize, getPreviewableFormats } from '../utils/conversionUtils';

const FileUpload = ({ 
  inputFiles, 
  setInputFiles, 
  fileInputRef, 
  isDragging, 
  setIsDragging, 
  isConverting,
  targetFormat
   
}) => {
  const [error, setError] = useState(null);
  const previewableFormats = getPreviewableFormats();

  const handleFiles = async (fileList) => {
    if (!fileList || fileList.length === 0) return;

    try {
      const files = Array.from(fileList);
      await validateFiles(files, targetFormat);
      
      const newFiles = files.map(file => {
        const extension = `.${file.name.split('.').pop().toLowerCase()}`;
        return {
          file,
          id: Math.random().toString(36).substring(7),
          previewSrc: previewableFormats.includes(extension) 
            ? URL.createObjectURL(file) 
            : null,
          format: file.name.split('.').pop().toUpperCase(),
          size: file.size
        };
      });

      setInputFiles(prevFiles => [...prevFiles, ...newFiles]);
      trackFileUpload(files);
      setError(null);
    } catch (error) {
      setError(error.message);
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset file input
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const items = e.dataTransfer.items;
    const files = e.dataTransfer.files;

    if (items?.length > 0) {
      if (items[0].webkitGetAsEntry?.()?.isDirectory) {
        setError('Folder upload is not supported');
        return;
      }
    }

    if (files?.length > 0) {
      await handleFiles(files);
    }
  };

  const handleFileInput = async (e) => {
    if (e.target.files?.length > 0) {
      await handleFiles(e.target.files);
      // Reset input value to allow selecting the same file again
      e.target.value = '';
    }
  };

  const removeFile = (fileId) => {
    setInputFiles(prevFiles => {
      const filesToKeep = prevFiles.filter(f => f.id !== fileId);
      // Revoke object URL when removing file
      const fileToRemove = prevFiles.find(f => f.id === fileId);
      if (fileToRemove?.previewSrc) {
        URL.revokeObjectURL(fileToRemove.previewSrc);
      }
      return filesToKeep;
    });
  };

  // Clean up object URLs when component unmounts
  React.useEffect(() => {
    return () => {
      inputFiles.forEach(file => {
        if (file.previewSrc) {
          URL.revokeObjectURL(file.previewSrc);
        }
      });
    };
  }, []);

  // Construct accept string for input using all supported formats
  const acceptTypes = getSupportedMimeTypes().join(',');

  if (inputFiles.length === 0) {
    return (
      <div
        className={`w-full p-8 border-2 border-dashed rounded-lg transition-colors ${
          isDragging ? 'border-purple-500 bg-purple-50' : 'border-gray-200'
        }`}
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsDragging(false);
        }}
      >
        <ErrorAlert error={error} onDismiss={() => setError(null)} />
        
        <div className="text-center">
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-600">
            Drag and drop your files here
          </p>
          <p className="mt-1 text-xs text-gray-500">
            or
          </p>
          <button
            onClick={() => fileInputRef.current?.click()}
            disabled={isConverting}
            className="mt-2 px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-colors disabled:opacity-50"
          >
            Browse Files
          </button>
          <p className="mt-1 text-xs text-gray-500">
            Maximum file size: 20MB
          </p>
        </div>

        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileInput}
          accept={acceptTypes}
          multiple
          className="hidden"
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <ErrorAlert error={error} onDismiss={() => setError(null)} />
      
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mb-4">
        {inputFiles.map(({ id, previewSrc, file, format, size }) => (
          <div key={id} className="relative group bg-white rounded-lg shadow-sm overflow-hidden aspect-square">
            <div className="absolute inset-0 flex items-center justify-center">
              {previewSrc ? (
                <img 
                  src={previewSrc} 
                  alt="File preview"
                  className="max-w-full max-h-full object-contain p-2" 
                />
              ) : (
                <div className="flex flex-col items-center justify-center text-gray-500 bg-gray-50 w-full h-full p-2">
                  <div className="text-lg font-bold mb-1">{format}</div>
                  <FileText className="w-8 h-8 mb-1" />
                  <span className="text-xs text-center truncate max-w-full">
                    {file.name}
                  </span>
                  <span className="text-xs text-gray-400">
                    {formatFileSize(size)}
                  </span>
                </div>
              )}
            </div>
            <button
              onClick={() => removeFile(id)}
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
              disabled={isConverting}
            >
              ×
            </button>
          </div>
        ))}
        
        {!isConverting && (
          <button 
            onClick={() => fileInputRef.current?.click()}
            className="aspect-square rounded-lg border-2 border-dashed border-purple-300 flex items-center justify-center text-purple-500 hover:bg-purple-50 transition-colors"
          >
            <Plus className="w-8 h-8" />
          </button>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        onChange={handleFileInput}
        accept={acceptTypes}
        multiple
        className="hidden"
      />
    </div>
  );
};

export default FileUpload;
import React, { useState, useRef } from 'react';
import { GripVertical, Loader } from 'lucide-react';

const ImageComparisonSlider = ({ originalImage, compressedImage, isCompressing }) => {
    const [sliderPosition, setSliderPosition] = useState(50);
    const sliderRef = useRef(null);

    const handleMove = (clientX) => {
        if (isCompressing || !sliderRef.current) return;
        const rect = sliderRef.current.getBoundingClientRect();
        let pos = ((clientX - rect.left) / rect.width) * 100;
        pos = Math.max(0, Math.min(pos, 100));
        setSliderPosition(pos);
    };

    const handleMouseDown = (e) => {
        if (isCompressing) return;
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        handleMove(e.clientX);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleTouchStart = (e) => {
        if (isCompressing) return;
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('touchend', handleTouchEnd);
    };

    const handleTouchMove = (e) => {
        e.preventDefault();
        if (e.touches?.[0]) {
            handleMove(e.touches[0].clientX);
        }
    };

    const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
    };

    return (
        <div className="relative w-full h-full overflow-hidden" ref={sliderRef}>
            {!isCompressing && (
                <>
                    <div className="absolute inset-0">
                        <img
                            src={originalImage}
                            alt="Original"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div
                        className="absolute inset-0 overflow-hidden"
                        style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
                    >
                        <img
                            src={compressedImage || originalImage}
                            alt="Compressed"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="absolute inset-0 pointer-events-none flex items-center justify-between px-4 z-10">
                        <div className="bg-black bg-opacity-70 text-white px-3 py-1 text-sm rounded-full shadow-md">
                            Before
                        </div>
                        <div className="bg-black bg-opacity-70 text-white px-3 py-1 text-sm rounded-full shadow-md">
                            After
                        </div>
                    </div>
                    <div
                        className="absolute top-0 bottom-0 w-0.5 cursor-ew-resize z-20 touch-pan-x"
                        style={{ 
                            left: `${sliderPosition}%`,
                            background: 'white',
                            boxShadow: '0 0 10px rgba(0,0,0,0.3)'
                        }}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleTouchStart}
                    >
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                                      w-10 h-10 bg-white rounded-full shadow-lg flex items-center justify-center
                                      hover:scale-110 transition-transform">
                            <GripVertical className="w-5 h-5 text-gray-600" />
                        </div>
                    </div>
                </>
            )}
            {isCompressing && (
                <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
                    <Loader className="w-12 h-12 text-purple-500 animate-spin" />
                    <span className="ml-2 text-lg font-medium text-gray-700">Compressing...</span>
                </div>
            )}
        </div>
    );
};

export default ImageComparisonSlider;
import React from 'react';
import BaseConverter from './BaseConverter';

const ConvertToWebP = () => (
  <BaseConverter
    format="webp"
    title="Convert to WebP"
    description="Convert your images to modern WebP format"
  />
);

export default ConvertToWebP;
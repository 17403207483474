// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppRoutes from './Routes';
import { initGA } from './Components/GoogleAnalytics';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Initialize GA4 before rendering
initGA();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
        <GoogleOAuthProvider clientId="1077305222099-evd1j7tn793r6th1nsel7u1t89d2er1k.apps.googleusercontent.com">

    <AppRoutes />
    </GoogleOAuthProvider>

  </React.StrictMode>
);

import React, { useEffect } from 'react';
import { CheckCircle, XCircle, X } from 'lucide-react';

export const Toast = ({ message, type = 'success', onClose, duration = 3000 }) => {
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const icons = {
    success: <CheckCircle className="w-5 h-5 text-green-500" />,
    error: <XCircle className="w-5 h-5 text-red-500" />
  };

  const backgrounds = {
    success: 'bg-green-50 border-green-100',
    error: 'bg-red-50 border-red-100'
  };

  const textColors = {
    success: 'text-green-800',
    error: 'text-red-800'
  };

  return (
    <div className={`fixed top-4 right-4 max-w-sm w-full z-50 animate-slide-in`}>
      <div className={`rounded-lg border p-4 ${backgrounds[type]} shadow-lg`}>
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {icons[type]}
          </div>
          <div className={`ml-3 flex-1 pt-0.5 ${textColors[type]}`}>
            {message}
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              onClick={onClose}
              className="inline-flex text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
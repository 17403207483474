// src/utils/analytics.js
export const GA_EVENTS = {
  FILE_UPLOAD: 'file_upload',
  CONVERSION_START: 'conversion_start',
  CONVERSION_COMPLETE: 'conversion_complete',
  CONVERSION_ERROR: 'conversion_error',
  FILE_DOWNLOAD: 'file_download',
  BUTTON_CLICK: 'button_click', 
  LOGIN_ATTEMPT: 'login_attempt',
  LOGIN_SUCCESS: 'login_success',
  LOGIN_ERROR: 'login_error',
  SIGNUP_ATTEMPT: 'signup_attempt',
  SIGNUP_SUCCESS: 'signup_success',
  SIGNUP_ERROR: 'signup_error',
  GOOGLE_AUTH_ATTEMPT: 'google_auth_attempt',
  GOOGLE_AUTH_SUCCESS: 'google_auth_success',
  GOOGLE_AUTH_ERROR: 'google_auth_error'
};

export const trackEvent = (eventName, eventParams = {}) => {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams);
  }
};

// Helper function to ensure array input
const ensureArray = (input) => {
  if (!input) return [];
  return Array.isArray(input) ? input : [input];
};

// Helper function to safely get file size
const getFileSize = (file) => {
  if (!file) return 0;
  if (file.size) return file.size;
  if (file.blob && file.blob.size) return file.blob.size;
  return 0;
};

export const trackFileUpload = (files) => {
  const fileArray = ensureArray(files);
  const fileDetails = fileArray.map(file => ({
    file_name: file.name,
    file_type: file.type,
    file_size_bytes: getFileSize(file),
    file_extension: file.name.split('.').pop().toLowerCase()
  }));

  trackEvent(GA_EVENTS.FILE_UPLOAD, {
    files_count: fileArray.length,
    total_size_bytes: fileArray.reduce((sum, file) => sum + getFileSize(file), 0),
    file_details: JSON.stringify(fileDetails),
    timestamp: new Date().toISOString()
  });
};

export const trackConversionStart = (files, targetFormat) => {
  const fileArray = ensureArray(files);
  const fileDetails = fileArray.map(file => ({
    original_name: file.file?.name || file.name || 'unknown',
    original_type: file.file?.type || file.type || 'unknown',
    original_size_bytes: getFileSize(file.file || file),
    original_extension: (file.file?.name || file.name || '').split('.').pop().toLowerCase()
  }));

  trackEvent(GA_EVENTS.CONVERSION_START, {
    files_count: fileArray.length,
    total_size_bytes: fileArray.reduce((sum, file) => sum + getFileSize(file.file || file), 0),
    target_format: targetFormat,
    source_formats: Array.from(new Set(fileDetails.map(f => f.original_extension))).join(','),
    file_details: JSON.stringify(fileDetails),
    timestamp: new Date().toISOString()
  });
};

export const trackConversionComplete = (
  conversionTimeMs,
  files,
  targetFormat,
  success,
  error = null,
  outputSizeBytes = 0
) => {
  const fileArray = ensureArray(files);
  const fileDetails = fileArray.map(file => ({
    original_name: file.file?.name || file.name || 'unknown',
    original_type: file.file?.type || file.type || 'unknown',
    original_size_bytes: getFileSize(file.file || file),
    original_extension: (file.file?.name || file.name || '').split('.').pop().toLowerCase()
  }));

  trackEvent(GA_EVENTS.CONVERSION_COMPLETE, {
    conversion_time_ms: conversionTimeMs,
    files_count: fileArray.length,
    total_input_size_bytes: fileArray.reduce((sum, file) => sum + getFileSize(file.file || file), 0),
    total_output_size_bytes: outputSizeBytes,
    compression_ratio: outputSizeBytes > 0 ? 
      (fileArray.reduce((sum, file) => sum + getFileSize(file.file || file), 0) / outputSizeBytes).toFixed(2) : 
      null,
    source_formats: Array.from(new Set(fileDetails.map(f => f.original_extension))).join(','),
    target_format: targetFormat,
    success: success,
    error_message: error,
    file_details: JSON.stringify(fileDetails),
    timestamp: new Date().toISOString()
  });
};

export const trackConversionError = (error, files, targetFormat) => {
  const fileArray = ensureArray(files);
  const fileDetails = fileArray.map(file => ({
    original_name: file.file?.name || file.name || 'unknown',
    original_type: file.file?.type || file.type || 'unknown',
    original_size_bytes: getFileSize(file.file || file),
    original_extension: (file.file?.name || file.name || '').split('.').pop().toLowerCase()
  }));

  trackEvent(GA_EVENTS.CONVERSION_ERROR, {
    error_message: error.message || String(error),
    error_code: error.code || 'unknown',
    error_stack: error.stack,
    files_count: fileArray.length,
    total_size_bytes: fileArray.reduce((sum, file) => sum + getFileSize(file.file || file), 0),
    target_format: targetFormat,
    source_formats: Array.from(new Set(fileDetails.map(f => f.original_extension))).join(','),
    file_details: JSON.stringify(fileDetails),
    timestamp: new Date().toISOString()
  });
};

export const trackFileDownload = (files, conversionType) => {
  const fileArray = ensureArray(files);
  const totalBytes = fileArray.reduce((sum, file) => sum + getFileSize(file), 0);

  // Log the values for debugging
  console.log('Download tracking:', {
    files: fileArray,
    conversionType,
    totalBytes,
    fileCount: fileArray.length
  });

  trackEvent(GA_EVENTS.FILE_DOWNLOAD, {
    files_count: fileArray.length,
    total_size_bytes: totalBytes,
    conversion_type: conversionType || 'unknown', // Ensure we have a fallback value
    file_types: fileArray.map(file => file.name?.split('.').pop()?.toLowerCase() || 'unknown').join(','),
    timestamp: new Date().toISOString()
  });
};

export const trackButtonClick = (buttonId, buttonText, additionalParams = {}) => {
  trackEvent(GA_EVENTS.BUTTON_CLICK, {
    button_id: buttonId,
    button_text: buttonText,
    ...additionalParams,
    timestamp: new Date().toISOString()
  });
};

export const trackAuthEvent = (eventName, authMethod, errorMessage = null, additionalParams = {}) => {
  trackEvent(eventName, {
    auth_method: authMethod,
    error_message: errorMessage,
    ...additionalParams,
    timestamp: new Date().toISOString()
  });
};
import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Twitter } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = {
    conversion: [
      { name: 'JPG Converter', path: '/convert-to-JPG' },
      { name: 'PNG Converter', path: '/convert-to-png' },
      { name: 'WebP Converter', path: '/convert-to-webp' },
      { name: 'PDF Converter', path: '/convert-to-pdf' },
    ],
    tools: [
      { name: 'Crop Image', path: '/image-crop' },
      { name: 'Compress Image', path: '/image-compress' },
      { name: 'Resize Image', path: '/image-resize' },
      { name: 'Upscale Image', path: '/image-upscale' },
    ],
    company: [
      { name: 'About', path: '/about' },
      { name: 'Contact', path: '/contact' },
      { name: 'Blog', path: '/blog' },
    ],
    legal: [
      { name: 'Privacy Policy', path: '/privacy' },
      { name: 'Terms of Service', path: '/terms' },
      { name: 'Cookie Policy', path: '/cookies' },
    ]
  };

  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="max-w-4xl mx-auto px-6 py-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {/* Conversion Tools */}
          <div>
            <h3 className="text-[11px] font-semibold text-gray-500 uppercase mb-2">
              Convert
            </h3>
            <ul className="space-y-1.5">
              {footerLinks.conversion.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-[11px] text-gray-400 hover:text-purple-600 transition-colors"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Edit Tools */}
          <div>
            <h3 className="text-[11px] font-semibold text-gray-500 uppercase mb-2">
              Edit
            </h3>
            <ul className="space-y-1.5">
              {footerLinks.tools.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-[11px] text-gray-400 hover:text-purple-600 transition-colors"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="text-[11px] font-semibold text-gray-500 uppercase mb-2">
              Company
            </h3>
            <ul className="space-y-1.5">
              {footerLinks.company.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-[11px] text-gray-400 hover:text-purple-600 transition-colors"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-[11px] font-semibold text-gray-500 uppercase mb-2">
              Legal
            </h3>
            <ul className="space-y-1.5">
              {footerLinks.legal.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="text-[11px] text-gray-400 hover:text-purple-600 transition-colors"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-100 mt-4 pt-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
            <div className="flex items-center space-x-1.5">
              <Heart className="h-3 w-3 text-purple-500" />
              <span className="text-[11px] text-gray-400">
                © {currentYear} ilikeimg
              </span>
            </div>
            
            {/* Social Links */}
            <a
              href="https://twitter.com/ilikeimg"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-1 text-[11px] text-gray-400 hover:text-purple-600 transition-colors"
            >
              <Twitter className="h-3 w-3" />
              <span>Follow us</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
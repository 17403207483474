import React from 'react';
import { Loader } from 'lucide-react';
import { logInDev } from '../utils/environment';

export const ProgressIndicator = ({ phase, currentFile, totalFiles }) => {
  logInDev(`🔄 Progress Update - Phase: ${phase}, File ${currentFile}/${totalFiles}`);


  const getPhaseMessage = () => {
    switch (phase) {
      case 'transferring':
        return totalFiles > 1 
          ? `Transferring files (${currentFile}/${totalFiles})...`
          : 'Transferring file...';
      case 'converting':
        return totalFiles > 1
          ? `Converting files (${currentFile}/${totalFiles})...`
          : 'Converting file...';
      default:
        return 'Processing...';
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full max-w-md mx-auto text-center px-4">
      <div className="mb-6">
        <Loader className="w-12 h-12 text-purple-500 animate-spin" />
      </div>
      <h2 className="text-2xl font-bold mb-2 text-gray-800">
        {getPhaseMessage()}
      </h2>
      <p className="text-gray-600">
        Please wait while we process your {totalFiles > 1 ? 'files' : 'file'}
      </p>
    </div>
  );
};
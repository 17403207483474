import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext({
  user: null,
  setUser: () => {},
  login: () => {},
  logout: () => {},
  register: () => {},
  refreshUser: () => {},
  loading: false
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [refreshAttempts, setRefreshAttempts] = useState(0);
  const MAX_REFRESH_ATTEMPTS = 3;

  // Define logout first since it's used by other functions
  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setUser(null);
    setRefreshAttempts(0);
    navigate('/');
  }, [navigate]);

  const refreshToken = useCallback(async (force = false) => {
    try {
      const refresh_token = localStorage.getItem('refreshToken');
      if (!refresh_token) {
        throw new Error('No refresh token available');
      }

      if (!force && refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
        console.error('Max refresh attempts reached');
        throw new Error('Max refresh attempts reached');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${refresh_token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Token refresh failed');
      }

      const data = await response.json();
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      setRefreshAttempts(0); // Reset attempts on successful refresh
      return true;
    } catch (error) {
      console.error('Token refresh failed:', error);
      setRefreshAttempts(prev => prev + 1);
      return false;
    }
  }, [refreshAttempts]);

  const fetchUserData = useCallback(async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      if (!response.ok) {
        if (response.status === 401) {
          const refreshed = await refreshToken();
          if (refreshed) {
            return fetchUserData(localStorage.getItem('accessToken'));
          }
          throw new Error('Authentication failed');
        }
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }, [refreshToken]);

  const refreshUser = useCallback(async (force = false) => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('No access token found');
      }

      const userData = await fetchUserData(token);
      setUser(prevUser => {
        // Only update if force=true or data has changed
        if (force || !prevUser || JSON.stringify(prevUser) !== JSON.stringify(userData)) {
          return userData;
        }
        return prevUser;
      });
      return true;
    } catch (error) {
      console.error('Failed to refresh user data:', error);
      return false;
    }
  }, [fetchUserData]);

  const checkAuthStatus = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setLoading(false);
        return;
      }

      const userData = await fetchUserData(token);
      setUser(userData);
    } catch (error) {
      console.error('Auth check failed:', error);
      logout();
    } finally {
      setLoading(false);
    }
  }, [fetchUserData, logout]);

  const login = useCallback(async (identifier, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ identifier, password })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Login failed');
      }

      const data = await response.json();
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);

      await checkAuthStatus();
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  }, [checkAuthStatus]);

  const register = useCallback(async (email, username, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, username, password })
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.detail || 'Registration failed');
      }

      return {
        success: true,
        message: data.message
      };
    } catch (error) {
      return {
        success: false,
        error: error.message
      };
    }
  }, []);

  // Initial auth check
  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  // Auto refresh token before expiry
  useEffect(() => {
    if (user) {
      const refreshInterval = setInterval(() => {
        refreshToken(true); // Force refresh regardless of attempts
      }, 25 * 60 * 1000); // 25 minutes

      return () => clearInterval(refreshInterval);
    }
  }, [user, refreshToken]);

  // Reset refresh attempts periodically
  useEffect(() => {
    if (refreshAttempts > 0) {
      const resetTimer = setTimeout(() => {
        setRefreshAttempts(0);
      }, 5 * 60 * 1000); // Reset after 5 minutes

      return () => clearTimeout(resetTimer);
    }
  }, [refreshAttempts]);

  const value = {
    user,
    setUser,
    login,
    logout,
    register,
    refreshUser,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ProgressIndicator } from './ProgressIndicator';
import DownloadFile from './DownloadFile';
import FileUpload from './FileUpload';
import { useFileConversion } from '../hooks/useFileConversion';
import {
  getSupportedFormatsDisplay,
  FORMAT_CATEGORIES,
} from '../utils/conversionUtils';
import { isDevelopment } from '../utils/environment';

const BaseConverter = ({ format, title, description }) => {
  const [inputFiles, setInputFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const {
    convertedFiles,
    isProcessing,
    phase,
    currentFile,
    totalFiles,
    error,
    convertFiles,
    reset
  } = useFileConversion(format);

  const handleConversion = async () => {
    if (isDevelopment) {
      console.log(`🎬 Starting ${format} conversion for ${inputFiles.length} files`);
    }
    try {
      // Extract the actual File objects from the inputFiles array
      const files = inputFiles.map(f => f.file);
      await convertFiles(files);
    } catch (error) {
      if (isDevelopment) {
        console.log('❌ Conversion failed:', error);
      }
    }
  };

  const handleReset = () => {
    if (isDevelopment) {
      console.log('🔄 Resetting converter state');
    }
    reset();
    setInputFiles([]);
  };

  // Render different views based on state
  const renderContent = () => {
    if (isProcessing) {
      return (
        <ProgressIndicator
          phase={phase}
          currentFile={currentFile}
          totalFiles={totalFiles}
        />
      );
    }

    if (convertedFiles) {
      return (
        <DownloadFile
          files={convertedFiles}
          onBack={handleReset}
          toolName={title}
          toolIdentifier={`${format}-conversion`}
        />
      );
    }

    return (
      <>
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            {title}
          </h1>
          <p className="text-sm text-gray-600 mb-2">{description}</p>
          <p className="text-xs text-gray-600">
            Supported formats
          </p>
          <p className="text-xs text-gray-600">
            {getSupportedFormatsDisplay()
              .map(category => category.formats.join(', '))
              .join(' • ')}
          </p>
        </div>

        {error && (
          <div className="mb-4">
            <div className="flex items-start bg-red-50 text-red-700 p-2 rounded-lg mb-2 text-sm">
              <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0 mt-0.5" />
              <span>{error}</span>
            </div>
          </div>
        )}

        <div className="mb-8">
          <FileUpload
            inputFiles={inputFiles}
            setInputFiles={setInputFiles}
            fileInputRef={fileInputRef}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            isConverting={isProcessing}
            targetFormat={format}
            onConvert={handleConversion}
          />
        </div>

        {inputFiles.length > 0 && (
          <div className="fixed bottom-8 right-8">
            <div className="relative flex items-center justify-center">
              <span className="absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75 animate-ping"></span>
              <button
                onClick={handleConversion}
                disabled={isProcessing || inputFiles.length === 0}
                className="relative px-8 py-4 bg-purple-500 text-white rounded-full text-lg font-medium hover:bg-purple-600 transition-colors duration-200 shadow-lg flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isProcessing ? `Converting to ${format.toUpperCase()}...` : `Convert to ${format.toUpperCase()}`}
              </button>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center text-center p-4">
          <div className="inline-flex flex-col items-center gap-2 text-sm">
            <h2 className="font-medium text-gray-500 mb-1">Other tools:</h2>
            <div className="flex flex-wrap gap-2 justify-center items-center">
              {Object.values(FORMAT_CATEGORIES).flat().map((tool) => (
                tool.path !== `/convert-to-${format}` && (
                  <Link
                    key={tool.path}
                    to={tool.path}
                    className="text-purple-600 hover:text-purple-700 underline transition-colors"
                  >
                    {tool.name}
                  </Link>
                )
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title} - Free Online Tool | ilikeimg</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="container mx-auto px-4 py-6 max-w-3xl">
        {renderContent()}
      </div>
    </>
  );
};

export default BaseConverter;
import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Upload, ChevronRight, Settings } from 'lucide-react';
import DownloadFile from './DownloadFile';
import CropBox from './CropBox';

// Settings Drawer Component
const SettingsDrawer = ({ isOpen, onClose, cropArea, imageSize, aspect, isFreeAspect, onParameterChange, onAspectRatioChange, fileSize, croppedSize }) => {
    return (
        <div className={`fixed top-0 right-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-30 h-full ${isOpen ? 'translate-x-0' : 'translate-x-full'} sm:translate-x-0 sm:relative sm:transform-none sm:z-0`}>
            <div className="p-4 h-full flex flex-col overflow-y-auto">
                <div className="mb-4">
                    <h3 className="text-lg font-medium">Crop Settings</h3>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">File Information</label>
                    <p className="text-sm text-gray-600">Original size: {fileSize}</p>
                    <p className="text-sm text-gray-600">Estimated size: {croppedSize}</p>
                    <p className="text-sm text-gray-600">Dimensions: {imageSize.width}x{imageSize.height}px</p>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Aspect Ratio</label>
                    <div className="flex flex-wrap gap-2">
                        {['1:1', '4:3', '16:9', 'Free'].map((ratio) => (
                            <button
                                key={ratio}
                                className={`px-2 py-1 rounded ${aspect === ratio || (ratio === 'Free' && isFreeAspect) ? 'bg-purple-500 text-white' : 'bg-gray-200'}`}
                                onClick={() => onAspectRatioChange(ratio)}
                            >
                                {ratio}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Dimensions</label>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <label htmlFor="width-size" className="block text-xs font-medium text-gray-500">Width (px)</label>
                            <input
                                id="width-size"
                                type="number"
                                value={Math.round(cropArea.width)}
                                onChange={(e) => onParameterChange('width', e.target.value)}
                                min={10}
                                max={imageSize.width}
                                className="w-full px-2 py-1 border rounded"
                            />
                        </div>
                        <div>
                            <label htmlFor="height-size" className="block text-xs font-medium text-gray-500">Height (px)</label>
                            <input
                                id="height-size"
                                type="number"
                                value={Math.round(cropArea.height)}
                                onChange={(e) => onParameterChange('height', e.target.value)}
                                min={10}
                                max={imageSize.height}
                                className="w-full px-2 py-1 border rounded"
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Position</label>
                    <div className="grid grid-cols-2 gap-2">
                        <div>
                            <label htmlFor="x-position" className="block text-xs font-medium text-gray-500">X (px)</label>
                            <input
                                id="x-position"
                                type="number"
                                value={Math.round(cropArea.x)}
                                onChange={(e) => onParameterChange('x', e.target.value)}
                                min={0}
                                max={imageSize.width - cropArea.width}
                                className="w-full px-2 py-1 border rounded"
                            />
                        </div>
                        <div>
                            <label htmlFor="y-position" className="block text-xs font-medium text-gray-500">Y (px)</label>
                            <input
                                id="y-position"
                                type="number"
                                value={Math.round(cropArea.y)}
                                onChange={(e) => onParameterChange('y', e.target.value)}
                                min={0}
                                max={imageSize.height - cropArea.height}
                                className="w-full px-2 py-1 border rounded"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CropImage = () => {
    const [state, setState] = useState({
        image: null,
        imageSize: { width: 0, height: 0 },
        displaySize: { width: 0, height: 0 },
        cropArea: { x: 0, y: 0, width: 0, height: 0 },
        aspect: '1:1',
        drawerOpen: false,
        croppedImage: null,
        isFreeAspect: false,
        scaleFactor: 1,
        fileSize: '',
        croppedSize: '',
    });
    
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const dropZoneRef = useRef(null);

    // Helper function to determine aspect ratio
    const determineAspectRatio = (width, height) => {
        const ratio = width / height;
        const tolerance = 0.1; // Increased tolerance for more flexible matching
    
        // Common aspect ratios
        const commonRatios = {
            '1:1': 1,
            '4:3': 4/3,
            '16:9': 16/9
        };
    
        // Find the closest match
        let closestRatio = 'Free';
        let minDifference = Number.MAX_VALUE;
    
        Object.entries(commonRatios).forEach(([name, value]) => {
            const difference = Math.abs(ratio - value);
            if (difference < tolerance && difference < minDifference) {
                minDifference = difference;
                closestRatio = name;
            }
        });
    
        return closestRatio;
    };
    
    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleFile = (file) => {
        const formattedFileSize = formatFileSize(file.size);
        const reader = new FileReader();
        reader.onload = (e) => {
            setState(prevState => ({
                ...prevState,
                image: e.target.result,
                fileSize: formattedFileSize,
                drawerOpen: window.innerWidth >= 640, // Only open drawer by default on desktop
            }));
        };
        reader.readAsDataURL(file);
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            handleFile(event.target.files[0]);
        }
    };

    useEffect(() => {
        const dropZone = dropZoneRef.current;
        if (!dropZone) return;

        const handleDragEnter = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
        };

        const handleDragLeave = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
        };

        const handleDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDrop = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);

            const files = e.dataTransfer.files;
            if (files && files.length > 0) {
                handleFile(files[0]);
            }
        };

        dropZone.addEventListener('dragenter', handleDragEnter);
        dropZone.addEventListener('dragleave', handleDragLeave);
        dropZone.addEventListener('dragover', handleDragOver);
        dropZone.addEventListener('drop', handleDrop);

        return () => {
            dropZone.removeEventListener('dragenter', handleDragEnter);
            dropZone.removeEventListener('dragleave', handleDragLeave);
            dropZone.removeEventListener('dragover', handleDragOver);
            dropZone.removeEventListener('drop', handleDrop);
        };
    }, []);

    useEffect(() => {
        if (state.image && containerRef.current) {
            const img = new Image();
            img.onload = () => {
                const containerWidth = containerRef.current.clientWidth;
                const containerHeight = containerRef.current.clientHeight;
                const imageAspectRatio = img.width / img.height;
                const containerAspectRatio = containerWidth / containerHeight;

                let newWidth, newHeight, scaleFactor;

                if (imageAspectRatio > containerAspectRatio) {
                    newWidth = containerWidth;
                    newHeight = containerWidth / imageAspectRatio;
                    scaleFactor = containerWidth / img.width;
                } else {
                    newHeight = containerHeight;
                    newWidth = containerHeight * imageAspectRatio;
                    scaleFactor = containerHeight / img.height;
                }

                const cropWidth = newWidth / 2;
                const cropHeight = newHeight / 2;
                const cropX = (img.width - cropWidth / scaleFactor) / 2;
                const cropY = (img.height - cropHeight / scaleFactor) / 2;

                const newCropArea = {
                    x: cropX,
                    y: cropY,
                    width: cropWidth / scaleFactor,
                    height: cropHeight / scaleFactor
                };

                setState(prevState => ({
                    ...prevState,
                    imageSize: { width: img.width, height: img.height },
                    displaySize: { width: newWidth, height: newHeight },
                    cropArea: newCropArea,
                    scaleFactor: scaleFactor
                }));

                updateCroppedSize(
                    newCropArea, 
                    { width: img.width, height: img.height }, 
                    state.fileSize
                );
            };
            img.src = state.image;
        }
    }, [state.image]);

    useEffect(() => {
        // Handle drawer state on window resize
        const handleResize = () => {
            if (state.image) {
                setState(prevState => ({
                    ...prevState,
                    drawerOpen: window.innerWidth >= 640
                }));
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [state.image]);

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const toggleDrawer = () => {
        setState(prevState => ({
            ...prevState,
            drawerOpen: !prevState.drawerOpen
        }));
    };

    const updateCroppedSize = (cropArea, imageSize, fileSize) => {
        if (!fileSize) return;
        
        const croppedPixels = cropArea.width * cropArea.height;
        const totalPixels = imageSize.width * imageSize.height;
        const croppedRatio = croppedPixels / totalPixels;
        
        const fileSizeNumber = parseFloat(fileSize.split(' ')[0]);
        const fileSizeUnit = fileSize.split(' ')[1];
        
        let fileSizeInBytes;
        switch (fileSizeUnit) {
            case 'KB':
                fileSizeInBytes = fileSizeNumber * 1024;
                break;
            case 'MB':
                fileSizeInBytes = fileSizeNumber * 1024 * 1024;
                break;
            case 'GB':
                fileSizeInBytes = fileSizeNumber * 1024 * 1024 * 1024;
                break;
            default:
                fileSizeInBytes = fileSizeNumber;
        }
        
        const estimatedSize = croppedRatio * fileSizeInBytes;
        const formattedCroppedSize = formatFileSize(estimatedSize);
        
        setState(prevState => ({
            ...prevState,
            croppedSize: formattedCroppedSize
        }));
    };

    const handleCropChange = (newCropArea) => {
        const aspectRatio = determineAspectRatio(newCropArea.width, newCropArea.height);
        const isFreeAspect = aspectRatio === 'Free';
    
        setState(prevState => ({
            ...prevState,
            cropArea: newCropArea,
            aspect: aspectRatio,
            isFreeAspect
        }));
    };


    const handleParameterChange = (parameter, value) => {
        const numValue = parseInt(value, 10);
        if (isNaN(numValue)) return;

        setState(prevState => {
            let newCropArea = { ...prevState.cropArea };
            const aspectRatio = prevState.isFreeAspect ? null : eval(prevState.aspect.replace(':', '/'));

            switch (parameter) {
                case 'x':
                    newCropArea.x = Math.max(0, Math.min(numValue, prevState.imageSize.width - newCropArea.width));
                    break;
                case 'y':
                    newCropArea.y = Math.max(0, Math.min(numValue, prevState.imageSize.height - newCropArea.height));
                    break;
                case 'width':
                case 'height':
                    const oldCenter = {
                        x: newCropArea.x + newCropArea.width / 2,
                        y: newCropArea.y + newCropArea.height / 2
                    };

                    if (parameter === 'width') {
                        newCropArea.width = numValue;
                        if (aspectRatio) {
                            newCropArea.height = newCropArea.width / aspectRatio;
                        }
                    } else {
                        newCropArea.height = numValue;
                        if (aspectRatio) {
                            newCropArea.width = newCropArea.height * aspectRatio;
                        }
                    }

                    newCropArea.x = oldCenter.x - newCropArea.width / 2;
                    newCropArea.y = oldCenter.y - newCropArea.height / 2;

                    // Constrain to image boundaries
                    if (newCropArea.x < 0) {
                        newCropArea.x = 0;
                    } else if (newCropArea.x + newCropArea.width > prevState.imageSize.width) {
                        newCropArea.x = prevState.imageSize.width - newCropArea.width;
                    }

                    if (newCropArea.y < 0) {
                        newCropArea.y = 0;
                    } else if (newCropArea.y + newCropArea.height > prevState.imageSize.height) {
                        newCropArea.y = prevState.imageSize.height - newCropArea.height;
                    }

                    if (newCropArea.width > prevState.imageSize.width) {
                        newCropArea.width = prevState.imageSize.width;
                        if (aspectRatio) {
                            newCropArea.height = newCropArea.width / aspectRatio;
                        }
                    }
                    if (newCropArea.height > prevState.imageSize.height) {
                        newCropArea.height = prevState.imageSize.height;
                        if (aspectRatio) {
                            newCropArea.width = newCropArea.height * aspectRatio;
                        }
                    }
                    break;
            }

            // Determine the actual aspect ratio after all constraints
            const newAspectRatio = determineAspectRatio(newCropArea.width, newCropArea.height);
            const newIsFreeAspect = newAspectRatio === 'Free';

            updateCroppedSize(newCropArea, prevState.imageSize, prevState.fileSize);

            return {
                ...prevState,
                cropArea: newCropArea,
                aspect: newAspectRatio,
                isFreeAspect: newIsFreeAspect
            };
        });
    };

    const handleAspectRatioChange = (newAspect) => {
        setState(prevState => {
            const isFreeAspect = newAspect === 'Free';
            const aspect = isFreeAspect ? '1:1' : newAspect;
            const aspectRatio = isFreeAspect ? null : eval(aspect.replace(':', '/'));

            let newCropArea = { ...prevState.cropArea };

            if (aspectRatio) {
                if (newCropArea.width / newCropArea.height > aspectRatio) {
                    newCropArea.width = newCropArea.height * aspectRatio;
                } else {
                    newCropArea.height = newCropArea.width / aspectRatio;
                }

                if (newCropArea.x + newCropArea.width > prevState.imageSize.width) {
                    newCropArea.width = prevState.imageSize.width - newCropArea.x;
                    newCropArea.height = newCropArea.width / aspectRatio;
                }
                if (newCropArea.y + newCropArea.height > prevState.imageSize.height) {
                    newCropArea.height = prevState.imageSize.height - newCropArea.y;
                    newCropArea.width = newCropArea.height * aspectRatio;
                }
            }

            updateCroppedSize(newCropArea, prevState.imageSize, prevState.fileSize);

            return {
                ...prevState,
                aspect,
                isFreeAspect,
                cropArea: newCropArea
            };
        });
    };

    const getCroppedImg = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = state.cropArea.width;
        canvas.height = state.cropArea.height;
        const ctx = canvas.getContext('2d');

        const img = new Image();
        await new Promise((resolve) => {
            img.onload = resolve;
            img.src = state.image;
        });

        ctx.drawImage(
            img,
            state.cropArea.x,
            state.cropArea.y,
            state.cropArea.width,
            state.cropArea.height,
            0,
            0,
            state.cropArea.width,
            state.cropArea.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((file) => {
                resolve(file);
            }, 'image/jpeg');
        });
    };

    const handleCrop = async () => {
        try {
            const croppedImageBlob = await getCroppedImg();
            const timestamp = new Date().toISOString().replace(/[-:]/g, '').split('.')[0];
            const fileName = `ilikeimg.com_${timestamp}.jpg`;
            setState(prevState => ({
                ...prevState,
                croppedImage: [{ name: fileName, blob: croppedImageBlob }],
                drawerOpen: false
            }));
        } catch (e) {
            console.error('Error during crop:', e);
        }
    };

    const resetCrop = () => {
        setState({
            image: null,
            imageSize: { width: 0, height: 0 },
            displaySize: { width: 0, height: 0 },
            cropArea: { x: 0, y: 0, width: 0, height: 0 },
            aspect: '1:1',
            drawerOpen: false,
            croppedImage: null,
            isFreeAspect: false,
            scaleFactor: 1,
            fileSize: '',
            croppedSize: '',
        });
    };

 
    return (
        <>
            <Helmet>
                <title>Crop Images Online - Free Tool | ilikeimg</title>
                <meta name="description" content="Easily crop your images online with our free tool. Adjust size, position, aspect ratio, and more. No registration required." />
                <link rel="canonical" href="https://ilikeimg.com/image-crop" />
                <meta property="og:title" content="Crop Images Online - Free Tool | ilikeimg" />
                <meta property="og:description" content="Easily crop your images online with our free tool. Adjust size, position, aspect ratio, and more. No registration required." />
                <meta property="og:url" content="https://ilikeimg.com/image-crop" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Crop Images Online - Free Tool | ilikeimg" />
                <meta name="twitter:description" content="Easily crop your images online with our free tool. Adjust size, position, aspect ratio, and more. No registration required." />
            </Helmet>
            <div className="min-h-screen flex flex-col">
                {!state.image && (
                    <div className="text-center px-4 py-4">
                        <h2 className="text-3xl font-bold text-gray-900 mb-2">
                            Crop Image
                        </h2>
                        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                            Easily crop and resize your images online for free.
                        </p>
                    </div>
                )}
                
                <div className="flex flex-grow relative">
                    <div className="flex-grow p-4 sm:p-6 overflow-y-auto">
                        {state.croppedImage ? (
                            <DownloadFile
                                files={state.croppedImage}
                                onBack={resetCrop}
                                toolName="Crop Image"
                                toolIdentifier="image-crop"
                            />
                        ) : !state.image ? (
                            <div className="flex-grow flex justify-center items-start pt-4">
                                <div
                                    ref={dropZoneRef}
                                    className={`w-full max-w-3xl p-8 sm:p-12 flex flex-col items-center justify-center border-2 border-dashed rounded-lg transition-colors ${
                                        isDragging ? 'border-purple-500 bg-purple-50' : 'border-gray-300'
                                    }`}
                                >
                                    <div className="text-center">
                                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">Drag & drop an image here</h3>
                                        <p className="mt-1 text-sm text-gray-500">or</p>
                                        <button 
                                            onClick={triggerFileInput}
                                            className="mt-2 px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 text-sm sm:text-base inline-flex items-center"
                                        >
                                            Browse Files
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex-grow flex justify-center items-center" ref={containerRef}>
                                <div className="relative">
                                    <img
                                        ref={imageRef}
                                        src={state.image}
                                        alt="Original"
                                        style={{ 
                                            maxWidth: '100%', 
                                            maxHeight: 'calc(100vh - 250px)', 
                                            objectFit: 'contain' 
                                        }}
                                    />
                                    {state.displaySize.width > 0 && (
                                        <CropBox
                                            cropArea={state.cropArea}
                                            imageSize={state.imageSize}
                                            scaleFactor={state.scaleFactor}
                                            onCropChange={handleCropChange}
                                            aspect={state.aspect}
                                            isFreeAspect={state.isFreeAspect}
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/*"
                            className="hidden"
                        />
                    </div>

                    {state.image && !state.croppedImage && (
                        <div className="sm:w-64 flex-shrink-0">
                            <SettingsDrawer
                                isOpen={state.drawerOpen}
                                onClose={toggleDrawer}
                                cropArea={state.cropArea}
                                imageSize={state.imageSize}
                                aspect={state.aspect}
                                isFreeAspect={state.isFreeAspect}
                                onParameterChange={handleParameterChange}
                                onAspectRatioChange={handleAspectRatioChange}
                                fileSize={state.fileSize}
                                croppedSize={state.croppedSize}
                            />
                        </div>
                    )}
                </div>
                
                {state.image && !state.croppedImage && (
                    <>
                        <button
                            onClick={toggleDrawer}
                            className={`fixed right-0 top-1/2 transform -translate-y-1/2 bg-purple-500 text-white p-2 rounded-l-md shadow-md sm:hidden z-40 transition-colors ${state.drawerOpen ? 'bg-purple-600' : ''}`}
                        >
                            <Settings className="w-6 h-6" />
                        </button>
                        <button
                            onClick={handleCrop}
                            className="fixed bottom-6 right-6 bg-purple-500 text-white px-6 py-3 rounded-full shadow-lg inline-flex items-center justify-center hover:bg-purple-600 transition-colors z-50"
                        >
                            Crop Image
                            <ChevronRight className="w-5 h-5 ml-2" />
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

export default CropImage;
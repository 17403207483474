import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ImageComparisonSlider from './ImageComparisonSlider';
import { SAMPLE_CATEGORIES, getSamplesByCategory } from '../config/samples';

const ExampleCard = ({ sample, onClick, isSelected }) => (
    <motion.div
        layout
        whileHover={{ y: -5 }}
        onClick={onClick}
        className={`relative cursor-pointer rounded-xl overflow-hidden group
                   aspect-[4/3] ${isSelected ? 'ring-2 ring-purple-500 ring-offset-2' : 'hover:shadow-lg'}`}
    >
        <div className="absolute inset-0">
            <ImageComparisonSlider
                originalImage={sample.before}
                compressedImage={sample.after}
            />
        </div>
        
        {/* Hover overlay with text */}
        <div className="absolute inset-x-0 bottom-0 translate-y-full group-hover:translate-y-0 
                      transition-transform duration-300 ease-in-out">
            <div className="bg-gradient-to-t from-black/80 via-black/60 to-transparent 
                          backdrop-blur-[2px] pt-8 pb-4 px-4">
                <h3 className="font-medium text-white text-lg">{sample.title}</h3>
                <p className="text-sm text-gray-200 mt-1">{sample.description}</p>
            </div>
        </div>
    </motion.div>
);


const DetailView = ({ sample, onClose }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
    >
        <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-white rounded-2xl overflow-hidden max-w-4xl w-full shadow-xl"
        >
            <div className="aspect-[4/3] w-full relative">
                <div className="absolute inset-0">
                    <ImageComparisonSlider
                        originalImage={sample.before}
                        compressedImage={sample.after}
                    />
                </div>
            </div>
            <div className="p-6">
                <div className="flex items-start justify-between mb-4">
                    <div>
                        <h2 className="text-2xl font-bold text-gray-900">{sample.title}</h2>
                        <p className="text-gray-500">{sample.description}</p>
                    </div>
                    <button
                        onClick={onClose}
                        className="rounded-full p-2 hover:bg-gray-100 transition-colors"
                    >
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="space-y-4">
                    <div className="flex items-center space-x-4">
                        <span className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full text-sm">
                            {sample.category}
                        </span>
                        <span className="text-sm text-gray-500">
                            4x Resolution Enhancement
                        </span>
                    </div>
                    <p className="text-gray-600">
                        {sample.details || 'Slide to compare the original and enhanced versions. Notice the improved details and clarity while maintaining the natural look.'}
                    </p>
                </div>
            </div>
        </motion.div>
    </motion.div>
);

const SampleExamplesGrid = () => {
    const [selectedSample, setSelectedSample] = useState(null);
    const [filter, setFilter] = useState('All');
    const [filteredSamples, setFilteredSamples] = useState([]);

    useEffect(() => {
        const samples = getSamplesByCategory(filter);
        console.log('Filtered samples:', samples); // Debug log
        setFilteredSamples(samples);
    }, [filter]);

    const categories = ['All', ...Object.values(SAMPLE_CATEGORIES)];

    console.log('Rendering grid with samples:', filteredSamples); // Debug log

    return (
        <section className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold mb-4">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                            See it in Action
                        </span>
                    </h2>
                    <p className="text-gray-600 max-w-2xl mx-auto">
                        Explore real examples of our AI upscaling technology across different types of images
                    </p>
                </div>

                <div className="flex justify-center mb-8 gap-2 flex-wrap">
                    {categories.map(category => (
                        <button
                            key={category}
                            onClick={() => setFilter(category)}
                            className={`px-4 py-2 rounded-full transition-all text-sm
                                    ${filter === category 
                                        ? 'bg-purple-500 text-white' 
                                        : 'bg-white text-gray-600 hover:bg-gray-100'}`}
                        >
                            {category}
                        </button>
                    ))}
                </div>

                <motion.div layout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredSamples.map(sample => (
                        <ExampleCard
                            key={sample.id}
                            sample={sample}
                            onClick={() => setSelectedSample(sample)}
                            isSelected={selectedSample?.id === sample.id}
                        />
                    ))}
                </motion.div>

                <AnimatePresence>
                    {selectedSample && (
                        <DetailView
                            sample={selectedSample}
                            onClose={() => setSelectedSample(null)}
                        />
                    )}
                </AnimatePresence>
            </div>
        </section>
    );
};

export default SampleExamplesGrid;
import React, { useEffect, useContext, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CheckCircle, Loader, AlertCircle } from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { paymentService } from '../services/PaymentService';
import { trackEvent } from '../utils/analytics';

const CheckoutSuccess = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { refreshUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [verificationComplete, setVerificationComplete] = useState(false);
  const sessionId = searchParams.get('session_id');
  const MAX_RETRIES = 10; // Increased retries
  const RETRY_DELAY = 2000; // 2 seconds between retries

  useEffect(() => {
    const verifyCheckout = async () => {
      if (!sessionId) {
        setError('No session ID provided');
        setLoading(false);
        return;
      }

      try {
        console.log('Verifying checkout...', { sessionId, retryCount });
        
        // Get session status
        const status = await paymentService.getSessionStatus(
          sessionId,
          localStorage.getItem('accessToken')
        );
        
        console.log('Session status:', status);

        if (!status.isPremium) {
          if (retryCount >= MAX_RETRIES) {
            throw new Error('Subscription verification timeout. Please contact support if the issue persists.');
          }
          
          // Wait between retries
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
          setRetryCount(prev => prev + 1);
          return; // Will trigger another effect run
        }

        // Subscription is confirmed
        console.log('Premium status confirmed, refreshing user data');
        
        // Refresh user data multiple times to ensure we have the latest data
        let refreshAttempts = 3;
        while (refreshAttempts > 0) {
          await refreshUser();
          await new Promise(resolve => setTimeout(resolve, 1000));
          refreshAttempts--;
        }

        setVerificationComplete(true);
        setLoading(false);
        
        trackEvent('subscription_activated', {
          session_id: sessionId,
          retries: retryCount
        });

      } catch (err) {
        console.error('Verification error:', err);
        setError(err.message || 'Failed to verify subscription status');
        setLoading(false);
        
        trackEvent('subscription_activation_failed', {
          session_id: sessionId,
          error: err.message,
          retries: retryCount
        });
      }
    };

    if (loading && sessionId && !verificationComplete) {
      verifyCheckout();
    }
  }, [sessionId, refreshUser, loading, retryCount, verificationComplete]);

  const handleRetry = async () => {
    setLoading(true);
    setError(null);
    setRetryCount(0);
    setVerificationComplete(false);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <Loader className="w-12 h-12 text-purple-500 animate-spin mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Setting up your premium account...
          </h2>
          <p className="text-gray-600">
            {retryCount > 0 ? `Verifying subscription (Attempt ${retryCount}/${MAX_RETRIES})` : 'This will only take a moment.'}
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="bg-red-50 p-8 rounded-xl max-w-md">
            <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
            <h2 className="text-xl font-semibold text-red-800 mb-2">
              Something went wrong
            </h2>
            <p className="text-red-600 mb-6">{error}</p>
            <div className="space-y-4">
              <button
                onClick={handleRetry}
                className="w-full px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 
                         transition-colors duration-150"
              >
                Retry Verification
              </button>
              <button
                onClick={() => navigate('/settings')}
                className="w-full px-4 py-2 border border-gray-200 text-gray-700 rounded-lg 
                         hover:bg-gray-50 transition-colors duration-150"
              >
                Go to Settings
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-md w-full text-center">
        <div className="bg-white rounded-xl shadow-lg p-8">
          <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-6" />
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Welcome to Premium!
          </h1>
          <p className="text-gray-600 mb-8">
            Your account has been successfully upgraded. You now have access to all premium features!
          </p>
          <div className="space-y-4">
            <button
              onClick={() => {
                refreshUser(); // Refresh one more time before navigating
                navigate('/settings');
              }}
              className="w-full px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 
                       transition-colors duration-150"
            >
              View Your Account
            </button>
            <button
              onClick={() => navigate('/')}
              className="w-full px-6 py-3 border border-gray-200 text-gray-700 rounded-lg 
                       hover:bg-gray-50 transition-colors duration-150"
            >
              Go to Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
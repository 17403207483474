import React from 'react';
import BaseConverter from './BaseConverter';

const ConvertToPNG = () => (
  <BaseConverter
    format="png"  // Make sure this matches the backend's expected format
    title="Convert to PNG"
    description="Convert your images to PNG format with transparency support."
  />
);

export default ConvertToPNG;
import React from 'react';
import { Helmet } from 'react-helmet';

const DMCA = () => {
  return (
    <>
      <Helmet>
        <title>DMCA Policy - ilikeimg</title>
        <meta name="description" content="DMCA policy for ilikeimg - Copyright information and takedown procedures" />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">DMCA Policy</h1>
        <div className="prose prose-purple max-w-none">
          <p className="text-gray-600 mb-4">Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Copyright Notice</h2>
          <p className="text-gray-600 mb-4">
            We respect the intellectual property rights of others and expect our users to do the same.
            All image processing is done locally in your browser, and we do not store any user content.
          </p>

          <h2 className="text-xl font-semibold text-gray-900 mt-8 mb-4">Takedown Procedure</h2>
          <p className="text-gray-600 mb-4">
            If you believe that your work has been copied in a way that constitutes copyright infringement,
            please contact us with the following information...
          </p>

          {/* Add more sections as needed */}
        </div>
      </div>
    </>
  );
};

export default DMCA;
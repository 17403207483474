import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import {
  UserCircle,
  Mail,
  Lock,
  Loader,
  AlertCircle,
  Eye,
  EyeOff,
  Check,
  X,
  AtSign,
  RefreshCw,
  CheckCircle
} from 'lucide-react';
import { AuthContext } from '../../contexts/AuthContext';
import { Toast } from '../common/Toast';
import { trackAuthEvent, GA_EVENTS, trackEvent } from '../../utils/analytics';

const GoogleButton = ({ onClick, isLoading }) => (
  <button
    onClick={onClick}
    disabled={isLoading}
    className="w-full flex justify-center items-center px-4 py-2.5 
    border border-gray-200 rounded-xl text-sm font-medium text-gray-700 
    bg-white hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 
    focus:ring-purple-500 transition-all duration-150"
  >
    {isLoading ? (
      <Loader className="w-5 h-5 animate-spin" />
    ) : (
      <>
        <FcGoogle className="w-5 h-5 mr-2" />
        <span>Continue with Google</span>
      </>
    )}
  </button>
);

const Input = ({ icon: Icon, error, success, loading, ...props }) => (
  <div className="relative">
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <Icon className={`h-5 w-5 ${loading ? 'text-gray-400' :
        error ? 'text-red-400' :
          success ? 'text-green-400' :
            'text-gray-400'
        }`} />
    </div>
    <input
      {...props}
      className={`block w-full pl-10 pr-10 py-2.5 text-sm
        border ${loading ? 'border-gray-300' :
          error ? 'border-red-300' :
            success ? 'border-green-300' :
              'border-gray-300'
        } 
        rounded-xl placeholder-gray-400 
        focus:outline-none focus:ring-2 
        ${loading ? 'focus:ring-purple-500' :
          error ? 'focus:ring-red-500' :
            success ? 'focus:ring-green-500' :
              'focus:ring-purple-500'
        }
        focus:border-transparent transition-all duration-150
        disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed`}
    />
    {loading ? (
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
        <Loader className="h-5 w-5 text-gray-400 animate-spin" />
      </div>
    ) : (error || success) && (
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
        {error ? (
          <X className="h-5 w-5 text-red-400" />
        ) : (
          <Check className="h-5 w-5 text-green-400" />
        )}
      </div>
    )}
  </div>
);

const VerificationPendingMessage = ({ email, onResend, isResending }) => (
  <div className="mt-4 bg-purple-50 border border-purple-100 rounded-xl p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <AlertCircle className="h-5 w-5 text-purple-400" />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-purple-800">
          Verification Email Sent
        </h3>
        <div className="mt-2 text-sm text-purple-700">
          <p>
            We've sent a verification link to <strong>{email}</strong>.
            Please check your email to complete registration.
          </p>
        </div>
        <div className="mt-3">
          <button
            type="button"
            onClick={onResend}
            disabled={isResending}
            className="inline-flex items-center text-sm font-medium text-purple-800 
                       hover:text-purple-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isResending ? (
              <>
                <Loader className="w-4 h-4 mr-2 animate-spin" />
                Resending...
              </>
            ) : (
              <>
                <RefreshCw className="w-4 h-4 mr-2" />
                Resend Verification Email
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  </div>
);

const FieldError = ({ message }) => (
  <p className="mt-1 text-xs text-red-600 flex items-center">
    <X className="w-4 h-4 mr-1" />
    {message}
  </p>
);
const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { login , register, setUser } = useContext(AuthContext);


  const [isLogin, setIsLogin] = useState(location.state?.isLogin ?? true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [verificationPending, setVerificationPending] = useState(false);
  const [isResendingVerification, setIsResendingVerification] = useState(false);
  const [formData, setFormData] = useState({
    identifier: '',
    email: '',
    username: '',
    password: ''
  });
  const [googleLoading, setGoogleLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Track page view with auth type
  useEffect(() => {
    trackEvent('auth_page_view', {
      auth_type: isLogin ? 'login' : 'signup',
      referrer: document.referrer
    });
  }, [isLogin]);

  // Email validation with debounce
  useEffect(() => {
    if (!isLogin && formData.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      const timer = setTimeout(async () => {
        setCheckingEmail(true);
        setEmailExists(false);
        setEmailValid(false);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/auth/check-email?email=${encodeURIComponent(formData.email)}`
          );
          const data = await response.json();
          setEmailExists(data.exists);
          setEmailValid(!data.exists);
          setValidationErrors(prev => ({
            ...prev,
            email: data.exists ? 'This email is already registered' : null
          }));
        } catch (err) {
          console.error('Email check failed:', err);
          setValidationErrors(prev => ({
            ...prev,
            email: 'Failed to verify email availability'
          }));
        } finally {
          setCheckingEmail(false);
        }
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setEmailExists(false);
      setEmailValid(false);
      setCheckingEmail(false);
      setValidationErrors(prev => ({ ...prev, email: null }));
    }
  }, [formData.email, isLogin]);

  // Username validation
  useEffect(() => {
    if (!isLogin && formData.username) {
      const errors = [];
      if (formData.username.length < 3) {
        errors.push('Username must be at least 3 characters');
      }
      if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
        errors.push('Username can only contain letters, numbers, and underscores');
      }
      setValidationErrors(prev => ({
        ...prev,
        username: errors.length > 0 ? errors : null
      }));
    } else {
      setValidationErrors(prev => ({ ...prev, username: null }));
    }
  }, [formData.username, isLogin]);

  const resetStates = () => {
    setFormData({
      identifier: '',
      email: '',
      username: '',
      password: ''
    });
    setError(null);
    setEmailExists(false);
    setEmailValid(false);
    setValidationErrors({});
    setVerificationPending(false);
    setShowPassword(false);
  };
  const handleGoogleSuccess = async (tokenResponse) => {
    setGoogleLoading(true);
    trackAuthEvent(GA_EVENTS.GOOGLE_AUTH_ATTEMPT, 'google');

    try {
      if (!tokenResponse.access_token) {
        throw new Error('No access token received from Google');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: tokenResponse.access_token,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to authenticate with Google');
      }

      const data = await response.json();
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);

      // Fetch user data immediately after Google auth
      const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
        headers: {
          'Authorization': `Bearer ${data.access_token}`
        }
      });

      if (userResponse.ok) {
        const userData = await userResponse.json();
        setUser(userData);
      }

      trackAuthEvent(GA_EVENTS.GOOGLE_AUTH_SUCCESS, 'google');

      setToast({
        type: 'success',
        message: 'Successfully signed in with Google!'
      });

      setTimeout(() => {
        navigate(location.state?.returnUrl || '/');
      }, 1000);
    } catch (err) {
      console.error('Google authentication error:', err);
      trackAuthEvent(GA_EVENTS.GOOGLE_AUTH_ERROR, 'google', err.message);
      setError(err.message || 'Failed to sign in with Google');
    } finally {
      setGoogleLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: (error) => {
      console.error('Google login failed:', error);
      trackAuthEvent(GA_EVENTS.GOOGLE_AUTH_ERROR, 'google', error.message);
      setError('Google sign in failed. Please try again.');
    },
    flow: 'implicit',
    scope: 'email profile',
  });

  const handleResendVerification = async () => {
    setIsResendingVerification(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/resend-verification`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: formData.email })
        }
      );

      const data = await response.json();

      if (response.ok) {
        setToast({
          type: 'success',
          message: 'Verification email sent! Please check your inbox.'
        });
      } else {
        setError(data.detail || 'Failed to resend verification email');
      }
    } catch (err) {
      setError('Failed to resend verification email');
    } finally {
      setIsResendingVerification(false);
    }
  };

  const validateForm = () => {
    if (!isLogin) {
      if (emailExists || checkingEmail) return false;
      if (validationErrors.email || validationErrors.password || validationErrors.username) return false;
      if (!formData.email || !formData.password || !formData.username) return false;
      return true;
    }
    return formData.identifier && formData.password;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!validateForm()) {
      setError('Please correct the errors before submitting');
      return;
    }

    setLoading(true);
    try {
      if (isLogin) {
        trackAuthEvent(GA_EVENTS.LOGIN_ATTEMPT, 'email', null, {
          has_username: !formData.identifier.includes('@')
        });

        const success = await login(formData.identifier, formData.password);
        if (success) {
          trackAuthEvent(GA_EVENTS.LOGIN_SUCCESS, 'email');
          setToast({
            type: 'success',
            message: 'Welcome back!'
          });
          setTimeout(() => navigate(location.state?.returnUrl || '/'), 1000);
        } else {
          const error = 'Invalid username/email or password';
          trackAuthEvent(GA_EVENTS.LOGIN_ERROR, 'email', error);
          setError(error);
        }
      } else {
        trackAuthEvent(GA_EVENTS.SIGNUP_ATTEMPT, 'email');
        const response = await register(formData.email, formData.username, formData.password);
        if (response.error) {
          trackAuthEvent(GA_EVENTS.SIGNUP_ERROR, 'email', response.error);
          setError(response.error);
        } else {
          trackAuthEvent(GA_EVENTS.SIGNUP_SUCCESS, 'email');
          setVerificationPending(true);
          setToast({
            type: 'success',
            message: 'Account created! Please check your email.'
          });
        }
      }
    } catch (err) {
      const errorMessage = err.message || 'An unexpected error occurred';
      trackAuthEvent(
        isLogin ? GA_EVENTS.LOGIN_ERROR : GA_EVENTS.SIGNUP_ERROR,
        'email',
        errorMessage
      );
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{isLogin ? 'Sign In' : 'Create Account'} | ilikeimg</title>
      </Helmet>

      {toast && (
        <Toast
          type={toast.type}
          message={toast.message}
          onClose={() => setToast(null)}
        />
      )}

      <div className="min-h-screen flex">
        {/* Left Pane - Welcome Section */}
        <div className="hidden lg:flex lg:w-1/2 bg-purple-50 flex-col justify-center px-12">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              {isLogin ? 'Welcome back!' : 'Join ilikeimg'}
            </h1>
            <p className="text-lg text-gray-600 mb-6">
              {isLogin
                ? 'Sign in to access your account and continue with your image conversion journey.'
                : 'Create an account to unlock all features and start converting your images.'}
            </p>
            <div className="space-y-3">
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                <span className="text-sm text-gray-700">Free image tools</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                <span className="text-sm text-gray-700">Advanced AI tools</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="h-5 w-5 text-purple-500 mr-2" />
                <span className="text-sm text-gray-700">Access from any device</span>
              </div>
            </div>
          </div>
        </div>

        {/* Right Pane - Form Section */}
        <div className="flex-1 flex flex-col justify-center py-8 px-4 sm:px-6 lg:px-16 xl:px-20 bg-white">
          <div className="mx-auto w-full max-w-sm">
            <div className="flex justify-center lg:hidden">
              <UserCircle className="w-12 h-12 text-purple-600" />
            </div>

            <h2 className="mt-4 text-center lg:text-left text-xl font-bold text-gray-900">
              {isLogin ? 'Sign in to your account' : 'Create your account'}
            </h2>

            <p className="mt-1 text-center lg:text-left text-sm text-gray-600 mb-6">
              {isLogin ? "Don't have an account? " : "Already have an account? "}
              <button
                onClick={() => {
                  setIsLogin(!isLogin);
                  resetStates();
                }}
                className="font-medium text-purple-600 hover:text-purple-500"
              >
                {isLogin ? 'Sign up' : 'Sign in'}
              </button>
            </p>

            <div className="mt-6">
              <GoogleButton
                onClick={() => googleLogin()}
                isLoading={googleLoading}
              />

              <div className="relative my-4">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    or continue with email
                  </span>
                </div>
              </div>

              {error && (
                <div className="mb-4 p-3 rounded-xl bg-red-50 border border-red-100 flex items-start">
                  <AlertCircle className="w-5 h-5 text-red-500 mt-0.5 mr-2 flex-shrink-0" />
                  <span className="text-sm text-red-700">{error}</span>
                </div>
              )}

              {verificationPending ? (
                <VerificationPendingMessage
                  email={formData.email}
                  onResend={handleResendVerification}
                  isResending={isResendingVerification}
                />
              ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                  {isLogin ? (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email or Username
                      </label>
                      <Input
                        icon={AtSign}
                        type="text"
                        name="identifier"
                        required
                        value={formData.identifier}
                        onChange={e => setFormData(prev => ({ ...prev, identifier: e.target.value }))}
                        placeholder="Email or username"
                        autoComplete="username"
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Email address
                        </label>
                        <Input
                          icon={Mail}
                          type="email"
                          name="email"
                          required
                          value={formData.email}
                          onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                          placeholder="you@example.com"
                          autoComplete="email"
                          error={emailExists}
                          success={emailValid}
                          loading={checkingEmail}
                          disabled={checkingEmail}
                        />
                        {validationErrors.email && (
                          <FieldError message={validationErrors.email} />
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Username
                        </label>
                        <Input
                          icon={UserCircle}
                          type="text"
                          name="username"
                          required
                          value={formData.username}
                          onChange={e => setFormData(prev => ({ ...prev, username: e.target.value }))}
                          placeholder="johndoe"
                          autoComplete="off"
                          error={validationErrors.username}
                        />
                        {validationErrors.username && (
                          validationErrors.username.map((error, index) => (
                            <FieldError key={index} message={error} />
                          ))
                        )}
                      </div>
                    </>
                  )}

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Password
                    </label>
                    <div className="relative">
                      <Input
                        icon={Lock}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        required
                        value={formData.password}
                        onChange={e => setFormData(prev => ({ ...prev, password: e.target.value }))}
                        placeholder="••••••••"
                        autoComplete={isLogin ? "current-password" : "new-password"}
                        minLength={8}
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                        tabIndex={-1}
                      >
                        {showPassword ? (
                          <EyeOff className="h-5 w-5 text-gray-400" />
                        ) : (
                          <Eye className="h-5 w-5 text-gray-400" />
                        )}
                      </button>
                    </div>
                  </div>

                  {isLogin && (
                    <div className="flex items-center justify-end">
                      <button
                        type="button"
                        className="text-sm font-medium text-purple-600 hover:text-purple-500"
                        onClick={() => navigate('/forgot-password')}
                      >
                        Forgot your password?
                      </button>
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={loading || (!isLogin && !validateForm())}
                    className="w-full flex justify-center py-2.5 px-4 border border-transparent 
                        rounded-xl shadow-sm text-sm font-medium text-white bg-purple-600 
                        hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-purple-500 transition-all duration-150 disabled:opacity-50 
                        disabled:cursor-not-allowed mt-2"
                  >
                    {loading ? (
                      <Loader className="w-5 h-5 animate-spin" />
                    ) : isLogin ? (
                      'Sign in'
                    ) : (
                      'Create account'
                    )}
                  </button>

                  <div className="mt-3 text-center text-xs">
                    <p className="text-gray-500">
                      {isLogin ? "By signing in, you agree to our" : "By creating an account, you agree to our"}{' '}
                      <Link
                        to="/terms"
                        className="font-medium text-purple-600 hover:text-purple-500"
                      >
                        Terms
                      </Link>
                      ,{' '}
                      <Link
                        to="/privacy"
                        className="font-medium text-purple-600 hover:text-purple-500"
                      >
                        Privacy
                      </Link>
                      {' '}and{' '}
                      <Link
                        to="/cookies"
                        className="font-medium text-purple-600 hover:text-purple-500"
                      >
                        Cookies
                      </Link>
                    </p>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthPage;
import React, { useState, useEffect } from 'react';
import { X, Bug, ChevronUp, ChevronDown, Trash2 } from 'lucide-react';
import { isDevelopment } from '../utils/environment';

const GADebugger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    if (!isDevelopment) return;

    // Store original gtag function
    const originalGtag = window.gtag;

    // Override gtag to capture events
    window.gtag = function() {
      const eventData = {
        timestamp: new Date().toISOString(),
        command: arguments[0],
        args: Array.from(arguments).slice(1),
        id: Math.random().toString(36).substring(7)
      };

      setEvents(prev => [eventData, ...prev].slice(0, 100)); // Keep last 100 events

      // Log to console
      console.log('🔍 GA Event:', eventData);

      // Call original gtag function
      return originalGtag?.apply(this, arguments);
    };

    // Check GA setup on mount
    const setupStatus = {
      measurement_id: process.env.REACT_APP_GA_MEASUREMENT_ID,
      gtag_available: !!window.gtag,
      dataLayer_available: !!window.dataLayer,
      script_loaded: !!document.querySelector('script[src*="gtag/js"]')
    };

    console.log('📊 GA4 Setup Status:', setupStatus);

    return () => {
      // Restore original gtag on unmount
      window.gtag = originalGtag;
    };
  }, []);

  if (!isDevelopment) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isOpen ? (
        <button
          onClick={() => setIsOpen(true)}
          className="bg-purple-500 text-white p-3 rounded-full shadow-lg hover:bg-purple-600 transition-colors"
        >
          <Bug className="w-5 h-5" />
        </button>
      ) : (
        <div className="bg-white rounded-lg shadow-xl border border-gray-200 w-96">
          {/* Header */}
          <div className="flex items-center justify-between p-3 border-b border-gray-200 bg-gray-50 rounded-t-lg">
            <div className="flex items-center space-x-2">
              <Bug className="w-5 h-5 text-purple-500" />
              <h3 className="font-medium">GA Event Debugger</h3>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setIsMinimized(!isMinimized)}
                className="text-gray-500 hover:text-gray-700"
              >
                {isMinimized ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Body */}
          {!isMinimized && (
            <div className="max-h-96 overflow-y-auto">
              <div className="p-3 border-b border-gray-200 bg-gray-50 flex justify-between items-center">
                <span className="text-sm text-gray-600">
                  {events.length} events captured
                </span>
                <button
                  onClick={() => setEvents([])}
                  className="text-red-500 hover:text-red-600 flex items-center space-x-1 text-sm"
                >
                  <Trash2 className="w-4 h-4" />
                  <span>Clear</span>
                </button>
              </div>
              {events.length === 0 ? (
                <div className="p-4 text-center text-gray-500">
                  No events captured yet
                </div>
              ) : (
                <div className="divide-y divide-gray-100">
                  {events.map((event) => (
                    <div key={event.id} className="p-3 hover:bg-gray-50">
                      <div className="flex justify-between items-start mb-1">
                        <span className="font-medium text-sm">
                          {event.command === 'event' ? event.args[0] : event.command}
                        </span>
                        <span className="text-xs text-gray-500">
                          {new Date(event.timestamp).toLocaleTimeString()}
                        </span>
                      </div>
                      {event.args.length > 1 && (
                        <pre className="text-xs bg-gray-50 p-2 rounded mt-1 overflow-x-auto">
                          {JSON.stringify(event.args[1], null, 2)}
                        </pre>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GADebugger;
const API_URL = process.env.REACT_APP_API_URL;

export const paymentService = {
    async getSessionStatus(sessionId, token) {
        const response = await fetch(`${API_URL}/payments/session/${sessionId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
    
        if (!response.ok) {
            const error = await response.json();
            console.error('Session status error:', error);
            throw new Error(error.detail || 'Failed to get session status');
        }
    
        const data = await response.json();
        console.log('Session status response:', data);
        return data;
    },
      
    async checkPremiumStatus(token) {
        const response = await fetch(`${API_URL}/payments/status`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to check premium status');
        }

        return response.json();
    },

    async getPricing() {
        const response = await fetch(`${API_URL}/payments/pricing`, {
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
        
        if (!response.ok) {
            throw new Error('Failed to fetch pricing information');
        }
        
        return response.json();
    },

    async getUserCredits(token) {
        if (!token) {
            console.error('No token provided for getUserCredits');
            throw new Error('Authentication required');
        }

        const response = await fetch(`${API_URL}/payments/credits`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });
        
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.detail || 'Failed to fetch user credits');
        }
        
        return response.json();
    },

    async createCheckoutSession(token) {
        if (!token) {
            throw new Error('Authentication required');
        }

        const response = await fetch(`${API_URL}/payments/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.detail || 'Failed to create checkout session');
        }

        return response.json();
    },

    async cancelSubscription(token, surveyData) {
        if (!token) {
            throw new Error('Authentication required');
        }
    
        const response = await fetch(`${API_URL}/payments/cancel`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(surveyData)
        });
    
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.detail || 'Failed to cancel subscription');
        }
    
        return response.json();
    },
    
    async resumeSubscription(token) {
        if (!token) {
            throw new Error('Authentication required');
        }
    
        const response = await fetch(`${API_URL}/payments/resume`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    
        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.detail || 'Failed to resume subscription');
        }
    
        return response.json();
    },

    async useCredits(amount, token) {
        if (!token) {
            throw new Error('Authentication required');
        }

        const response = await fetch(`${API_URL}/payments/credits/use`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            },
            body: JSON.stringify({ credits: amount })
        });

        if (!response.ok) {
            const error = await response.json();
            throw new Error(error.detail || 'Failed to use credits');
        }

        return response.json();
    },

    async verifySubscription(token) {
        if (!token) {
            throw new Error('Authentication required');
        }

        try {
            const statusResponse = await this.checkPremiumStatus(token);
            if (statusResponse.isPremium) {
                // Also get fresh credit information
                const creditsResponse = await this.getUserCredits(token);
                return {
                    ...statusResponse,
                    credits: creditsResponse.credits
                };
            }
            return statusResponse;
        } catch (error) {
            console.error('Subscription verification failed:', error);
            throw new Error('Failed to verify subscription status');
        }
    }
};

// Add error handling utilities
export const handlePaymentError = (error) => {
    console.error('Payment service error:', error);
    
    if (error.message.includes('Authentication required')) {
        return {
            message: 'Please log in to continue',
            code: 'AUTH_REQUIRED'
        };
    }
    
    if (error.message.includes('Failed to fetch user credits')) {
        return {
            message: 'Unable to load credit information',
            code: 'CREDITS_FETCH_FAILED'
        };
    }
    
    return {
        message: 'An unexpected error occurred. Please try again later.',
        code: 'UNKNOWN_ERROR'
    };
};

// Add retry mechanism for critical operations
export const retryOperation = async (operation, maxRetries = 3, delay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
        try {
            return await operation();
        } catch (error) {
            if (i === maxRetries - 1) throw error;
            await new Promise(resolve => setTimeout(resolve, delay));
            continue;
        }
    }
};
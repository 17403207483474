import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
  CheckCircle, 
  XCircle, 
  Loader, 
  RefreshCw, 
  AlertCircle 
} from 'lucide-react';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  
  const [verificationState, setVerificationState] = useState({
    status: 'loading', // loading, success, error, expired
    message: '',
  });

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setVerificationState({
          status: 'error',
          message: 'No verification token provided'
        });
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/verify-email/${token}`,
          { method: 'GET' }
        );

        const data = await response.json();

        if (response.ok && data.success) {
          setVerificationState({
            status: 'success',
            message: 'Email verified successfully'
          });
          // Redirect to login after 3 seconds
          setTimeout(() => navigate('/auth', { state: { isLogin: true }}), 3000);
        } else {
          setVerificationState({
            status: data.message.includes('expired') ? 'expired' : 'error',
            message: data.message || 'Verification failed'
          });
        }
      } catch (error) {
        setVerificationState({
          status: 'error',
          message: 'Failed to verify email'
        });
      }
    };

    verifyEmail();
  }, [token, navigate]);

  const resendVerification = async () => {
    try {
      const email = searchParams.get('email');
      if (!email) {
        setVerificationState({
          status: 'error',
          message: 'Email address not found'
        });
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/resend-verification`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email })
        }
      );

      const data = await response.json();

      if (response.ok) {
        setVerificationState({
          status: 'success',
          message: 'Verification email sent. Please check your inbox.'
        });
      } else {
        setVerificationState({
          status: 'error',
          message: data.detail || 'Failed to resend verification email'
        });
      }
    } catch (error) {
      setVerificationState({
        status: 'error',
        message: 'Failed to resend verification email'
      });
    }
  };

  const getContent = () => {
    switch (verificationState.status) {
      case 'loading':
        return (
          <div className="flex flex-col items-center">
            <Loader className="w-16 h-16 text-purple-600 animate-spin mb-4" />
            <h2 className="text-xl font-semibold text-gray-900">
              Verifying your email
            </h2>
            <p className="mt-2 text-gray-600">
              Please wait while we verify your email address...
            </p>
          </div>
        );

      case 'success':
        return (
          <div className="flex flex-col items-center">
            <CheckCircle className="w-16 h-16 text-green-500 mb-4" />
            <h2 className="text-xl font-semibold text-gray-900">
              Email Verified!
            </h2>
            <p className="mt-2 text-gray-600">
              Your email has been verified successfully. 
              Redirecting you to login...
            </p>
          </div>
        );

      case 'expired':
        return (
          <div className="flex flex-col items-center">
            <AlertCircle className="w-16 h-16 text-yellow-500 mb-4" />
            <h2 className="text-xl font-semibold text-gray-900">
              Verification Link Expired
            </h2>
            <p className="mt-2 text-gray-600">
              The verification link has expired. Would you like to receive a new one?
            </p>
            <button
              onClick={resendVerification}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent 
                       rounded-md shadow-sm text-sm font-medium text-white 
                       bg-purple-600 hover:bg-purple-700 focus:outline-none 
                       focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              <RefreshCw className="w-4 h-4 mr-2" />
              Resend Verification Email
            </button>
          </div>
        );

      case 'error':
        return (
          <div className="flex flex-col items-center">
            <XCircle className="w-16 h-16 text-red-500 mb-4" />
            <h2 className="text-xl font-semibold text-gray-900">
              Verification Failed
            </h2>
            <p className="mt-2 text-gray-600">
              {verificationState.message}
            </p>
            <button
              onClick={() => navigate('/auth')}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent 
                       rounded-md shadow-sm text-sm font-medium text-white 
                       bg-purple-600 hover:bg-purple-700 focus:outline-none 
                       focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Return to Login
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <title>Verify Email | ilikeimg</title>
      </Helmet>

      <div className="min-h-screen flex flex-col justify-center bg-gradient-to-b from-purple-50 to-white py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {getContent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as LucideIcons from 'lucide-react';
import { AuthContext } from './contexts/AuthContext';

// Common tools configuration that can be exported and shared
export const TOOL_PATHS = {
  convert: {
    jpg: '/convert-to-JPG',
    png: '/convert-to-png',
    webp: '/convert-to-webp',
    pdf: '/convert-to-pdf',
  },
  edit: {
    crop: '/image-crop',
    compress: '/image-compress',
    resize: '/image-resize',
    upscale: '/image-upscale',
  }
};

const ToolCard = ({ 
  to, 
  icon, 
  title, 
  description, 
  requiresAuth = false,
  requiresAuthForAction = false,
  user 
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    console.log('ToolCard clicked:', {
      to,
      requiresAuth,
      requiresAuthForAction,
      user,
      isAuthenticated: !!user,
      currentLocation: location.pathname,
      title
    });

    // Always navigate to the tool page, regardless of auth status
    navigate(to);
  };

  return (
    <div 
      onClick={handleClick} 
      className="cursor-pointer"
      role="button"
      aria-label={`Use ${title} tool`}
      data-path={to}
    >
      <div className="bg-white p-6 rounded-xl transition-all duration-300 hover:shadow-md border border-gray-100 hover:border-purple-100">
        <div className="flex flex-col items-center">
          <div className="mb-4 p-3 rounded-lg transition-colors duration-300 bg-purple-50 text-purple-500 group-hover:bg-purple-100">
            {icon}
          </div>
          <h2 className="text-lg font-medium text-gray-900 mb-2">{title}</h2>
          <p className="text-sm text-gray-500 text-center">{description}</p>
          
          {requiresAuthForAction && !user && (
            <span className="mt-3 text-sm text-purple-600 flex items-center gap-1">
              <LucideIcons.Lock className="w-4 h-4" />
              Sign in required for {title.toLowerCase()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const SectionTitle = ({ children }) => (
  <h2 className="text-xl font-medium text-gray-900 mb-6">
    {children}
  </h2>
);

const App = () => {
  const { user } = useContext(AuthContext);

  const tools = {
    convert: [
      {
        to: TOOL_PATHS.convert.jpg,
        icon: <LucideIcons.Image className="w-6 h-6" />,
        title: 'JPG',
        description: 'Convert to JPEG format'
      },
      {
        to: TOOL_PATHS.convert.png,
        icon: <LucideIcons.Image className="w-6 h-6" />,
        title: 'PNG',
        description: 'Convert with transparency'
      },
      {
        to: TOOL_PATHS.convert.webp,
        icon: <LucideIcons.Image className="w-6 h-6" />,
        title: 'WebP',
        description: 'Modern web format'
      },
      {
        to: TOOL_PATHS.convert.pdf,
        icon: <LucideIcons.FileType className="w-6 h-6" />,
        title: 'PDF',
        description: 'Create PDF documents'
      }
    ],
    edit: [
      {
        to: TOOL_PATHS.edit.crop,
        icon: <LucideIcons.Crop className="w-6 h-6" />,
        title: 'Crop',
        description: 'Adjust image dimensions'
      },
      {
        to: TOOL_PATHS.edit.compress,
        icon: <LucideIcons.Minimize2 className="w-6 h-6" />,
        title: 'Compress',
        description: 'Reduce file size'
      },
      {
        to: TOOL_PATHS.edit.resize,
        icon: <LucideIcons.Move className="w-6 h-6" />,
        title: 'Resize',
        description: 'Change dimensions'
      },
      {
        to: TOOL_PATHS.edit.upscale,
        icon: <LucideIcons.Maximize2 className="w-6 h-6" />,
        title: 'Upscale',
        description: 'AI enhancement',
        requiresAuth: false, // Allow access to the component
        requiresAuthForAction: false // Indicate that auth is needed for upscaling
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>ilikeimg - Free Image Tools</title>
        <meta name="description" content="Free online tools to convert, crop, compress, resize and upscale your images." />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-5xl mx-auto px-4 py-12 sm:py-16 text-center">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4">
            Professional Image Tools, 
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              {' '}Free to Use
            </span>
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-12">
            Transform your images with our easy-to-use tools.
            No registration required for basic features.
          </p>
        </div>

        <div className="max-w-5xl mx-auto px-4 pb-16">
          <div className="mb-12">
            <SectionTitle>Convert Images</SectionTitle>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              {tools.convert.map((tool) => (
                <ToolCard
                  key={tool.to}
                  {...tool}
                  user={user}
                />
              ))}
            </div>
          </div>

          <div>
            <SectionTitle>Edit Images</SectionTitle>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              {tools.edit.map((tool) => (
                <ToolCard
                  key={tool.to}
                  {...tool}
                  user={user}
                />
              ))}
            </div>
          </div>

          <div className="mt-16 text-center">
            <div className="inline-flex items-center bg-gradient-to-r from-purple-50 to-blue-50 px-6 py-3 rounded-lg">
              <LucideIcons.Sparkles className="w-5 h-5 text-purple-500 mr-2" />
              <span className="text-gray-700">
                Try our AI-powered features with a free account
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
// src/utils/environment.js
export const isDevelopment = process.env.NODE_ENV === 'development';

export const logInDev = (...args) => {
  if (isDevelopment) {
    console.log(...args);
  }
};

export const getLogEmoji = {
  START: '🎬',
  TRANSFER: '📤',
  CONVERT: '🔄',
  SUCCESS: '✅',
  ERROR: '❌',
  KEY: '🔑',
  FILE: '📄',
  STATS: '📊',
  INFO: '💡'
};
import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Heart, 
  Menu, 
  X, 
  ChevronDown,
  Image as ImageIcon, 
  FileType,
  ArrowDownToLine,
  LogOut,
  Settings,
  Key,
  HelpCircle,
  Home
} from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { MenuItem } from './common/MenuItem';
import { MobileMenu } from './common/MobileMenu';
import { DropdownMenu } from './common/DropdownMenu';
import { TOOL_PATHS } from '../App';  // Import the shared paths

const Header = () => {
  const { user, logout, loading } = useContext(AuthContext);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [convertMenuOpen, setConvertMenuOpen] = useState(false);
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const closeAllMenus = () => {
    setConvertMenuOpen(false);
    setToolsMenuOpen(false);
    setUserMenuOpen(false);
  };

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  const getAvatarColor = (username) => {
    const colors = [
      'bg-purple-100 text-purple-600',
      'bg-blue-100 text-blue-600',
      'bg-green-100 text-green-600',
      'bg-pink-100 text-pink-600'
    ];
    const index = username.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const tools = {
    convert: [
      {
        to: TOOL_PATHS.convert.jpg,
        icon: ImageIcon,
        label: 'Convert to JPG',
        description: 'Create JPG images'
      },
      {
        to: TOOL_PATHS.convert.png,
        icon: ImageIcon,
        label: 'Convert to PNG',
        description: 'Convert with transparency'
      },
      {
        to: TOOL_PATHS.convert.webp,
        icon: ImageIcon,
        label: 'Convert to WebP',
        description: 'Modern web format'
      },
      {
        to: TOOL_PATHS.convert.pdf,
        icon: FileType,
        label: 'Convert to PDF',
        description: 'Create PDF documents'
      }
    ],
    edit: [
      {
        to: TOOL_PATHS.edit.compress,
        icon: Settings,
        label: 'Compress Image',
        description: 'Reduce file size'
      },
      {
        to: TOOL_PATHS.edit.resize,
        icon: Settings,
        label: 'Resize Image',
        description: 'Change dimensions'
      },
      {
        to: TOOL_PATHS.edit.upscale,
        icon: ArrowDownToLine,
        label: 'AI Upscale',
        description: 'Enhance resolution',
        requiresAuth: true,
        badge: 'PRO'
      }
    ]
  };

  const userMenuItems = [
    
    {
      icon: Key,
      label: 'Account Settings',
      to: '/settings'
    },
    {
      icon: HelpCircle,
      label: 'Help & Support',
      to: '/support'
    },
    {
      icon: LogOut,
      label: 'Sign Out',
      onClick: () => {
        logout();
        closeAllMenus();
      }
    }
  ];

  return (
    <nav className="bg-white border-b border-gray-200 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex items-center flex-shrink-0">
            <Link to="/" className="flex items-center">
              <Heart className="h-8 w-8 text-purple-500" />
              <span className="ml-2 text-2xl font-bold bg-gradient-to-r from-purple-600 to-purple-500 bg-clip-text text-transparent">
                ilikeimg
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden sm:flex sm:items-center sm:space-x-4">
            {/* Convert Dropdown */}
            <div 
              className="relative"
              onMouseEnter={() => {
                setConvertMenuOpen(true);
                setToolsMenuOpen(false);
                setUserMenuOpen(false);
              }}
            >
              <button className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-purple-600 hover:bg-gray-50">
                Convert
                <ChevronDown className={`ml-1 h-4 w-4 transition-transform duration-200 ${convertMenuOpen ? 'rotate-180' : ''}`} />
              </button>

              <DropdownMenu 
                isOpen={convertMenuOpen} 
                onClose={() => setConvertMenuOpen(false)}
                width="w-64"
                showOnHover={true}
              >
                {tools.convert.map((tool, index) => (
                  <MenuItem
                    key={tool.path}
                    {...tool}
                    delay={index * 0.05}
                    isActive={isActive(tool.path)}
                    onClick={() => setConvertMenuOpen(false)}
                    user={user}
                  />
                ))}
              </DropdownMenu>
            </div>

            {/* Tools Dropdown */}
            <div 
              className="relative"
              onMouseEnter={() => {
                setToolsMenuOpen(true);
                setConvertMenuOpen(false);
                setUserMenuOpen(false);
              }}
            >
              <button className="inline-flex items-center px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-purple-600 hover:bg-gray-50">
                Tools
                <ChevronDown className={`ml-1 h-4 w-4 transition-transform duration-200 ${toolsMenuOpen ? 'rotate-180' : ''}`} />
              </button>

              <DropdownMenu 
                isOpen={toolsMenuOpen} 
                onClose={() => setToolsMenuOpen(false)}
                width="w-64"
                showOnHover={true}
              >
                {tools.edit.map((tool, index) => (
                  <MenuItem
                    key={tool.path}
                    {...tool}
                    delay={index * 0.05}
                    isActive={isActive(tool.path)}
                    onClick={() => setToolsMenuOpen(false)}
                    user={user}
                  />
                ))}
              </DropdownMenu>
            </div>

            {/* Auth Section */}
            {!loading && (
              <div className="flex items-center space-x-4">
                {user ? (
                  <div 
                    className="relative"
                    onMouseEnter={() => {
                      setUserMenuOpen(true);
                      setConvertMenuOpen(false);
                      setToolsMenuOpen(false);
                    }}
                  >
                    <button className="flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-50 group transition-all duration-200">
                      <div className="flex items-center">
                        <div className={`h-8 w-8 rounded-full flex items-center justify-center font-medium ${getAvatarColor(user.username)}`}>
                          {user.username.charAt(0).toUpperCase()}
                        </div>
                        <div className="ml-2 text-left">
                          <p className="text-gray-700 font-medium group-hover:text-purple-600">
                            {user.username}
                          </p>
                          {user.email && (
                            <p className="text-xs text-gray-500">
                              {user.email}
                            </p>
                          )}
                        </div>
                        <ChevronDown className={`w-4 h-4 ml-2 text-gray-400 group-hover:text-purple-500 transition-transform duration-200 ${userMenuOpen ? 'rotate-180' : ''}`} />
                      </div>
                    </button>

                    <DropdownMenu 
                      isOpen={userMenuOpen} 
                      onClose={() => setUserMenuOpen(false)}
                      showOnHover={true}
                      width="w-72"
                    >
                      {/* User Info Header */}
                      <div className="px-4 py-3 border-b border-gray-100">
                        <div className="flex items-center">
                          <div className={`h-10 w-10 rounded-full flex items-center justify-center font-medium ${getAvatarColor(user.username)}`}>
                            {user.username.charAt(0).toUpperCase()}
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700">{user.username}</p>
                            {user.email && <p className="text-xs text-gray-500">{user.email}</p>}
                          </div>
                        </div>
                      </div>

                      {/* Menu Items */}
                      {userMenuItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          {...item}
                          delay={index * 0.05}
                          onClick={() => {
                            if (item.onClick) item.onClick();
                            setUserMenuOpen(false);
                          }}
                        />
                      ))}
                    </DropdownMenu>
                  </div>
                ) : (
                  <div className="flex items-center space-x-4">
                    <Link
                      to="/auth"
                      state={{ isLogin: true }}
                      className="text-gray-700 hover:text-purple-600"
                    >
                      Sign In
                    </Link>
                    <Link
                      to="/auth"
                      state={{ isLogin: false }}
                      className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700"
                    >
                      Sign Up
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="sm:hidden flex items-center">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-purple-600 hover:bg-gray-100"
            >
              {mobileMenuOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu isOpen={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <div className="px-4 py-6 space-y-6">
          {/* User Section */}
          {!loading && user && (
            <div className="border-b border-gray-200 pb-6">
              <div className="flex items-center">
                <div className={`h-12 w-12 rounded-full flex items-center justify-center font-medium ${getAvatarColor(user.username)}`}>
                  {user.username.charAt(0).toUpperCase()}
                </div>
                <div className="ml-3">
                  <p className="text-base font-medium text-gray-900">{user.username}</p>
                  {user.email && <p className="text-sm text-gray-500">{user.email}</p>}
                </div>
              </div>
            </div>
          )}

          {/* Convert Section */}
          <div>
            <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">
              Convert
            </h3>
            <div className="mt-3 space-y-1">
              {tools.convert.map((tool, index) => (
                <MenuItem
                  key={tool.path}
                  {...tool}
                  delay={index * 0.05}
                  isActive={isActive(tool.path)}
                  onClick={() => setMobileMenuOpen(false)}
                  user={user}
                />
              ))}
            </div>
          </div>

          {/* Tools Section */}
          <div>
            <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">
              Tools
            </h3>
            <div className="mt-3 space-y-1">
              {tools.edit.map((tool, index) => (
                <MenuItem
                  key={tool.path}
                  {...tool}
                  delay={index * 0.05}
                  isActive={isActive(tool.path)}
                  onClick={() => setMobileMenuOpen(false)}
                  user={user}
                />
              ))}
            </div>
          </div>

          {/* Auth Section for Mobile (when not logged in) */}
          {!loading && !user && (
            <div className="border-t border-gray-200 pt-4">
              <div className="grid grid-cols-2 gap-4">
                <Link
                  to="/auth"
                  state={{ isLogin: true }}
                  className="w-full flex justify-center py-2 px-4 border border-purple-600 rounded-md text-purple-600 hover:bg-purple-50 text-sm font-medium"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Sign In
                </Link>
                <Link
                  to="/auth"
                  state={{ isLogin: false }}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-white bg-purple-600 hover:bg-purple-700 text-sm font-medium"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Sign Up
                </Link>
              </div>
            </div>
          )}

          {/* Quick Links */}
          <div className="border-t border-gray-200 pt-4">
            <div className="px-4">
              <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">
                Quick Links
              </h3>
              <div className="mt-3 space-y-1">
                <MenuItem
                  icon={Home}
                  label="Home"
                  to="/"
                  onClick={() => setMobileMenuOpen(false)}
                  delay={0.15}
                />
<MenuItem
                  icon={HelpCircle}
                  label="Help & Support"
                  to="/support"
                  onClick={() => setMobileMenuOpen(false)}
                  delay={0.2}
                />
              </div>
            </div>
          </div>

          {/* User Menu Items (when logged in) */}
          {!loading && user && (
            <div className="border-t border-gray-200 pt-4">
              <div className="px-4">
                <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wider">
                  Account
                </h3>
                <div className="mt-3 space-y-1">
                  {userMenuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      {...item}
                      delay={index * 0.05}
                      onClick={() => {
                        if (item.onClick) item.onClick();
                        setMobileMenuOpen(false);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Version Info */}
          <div className="px-4 pt-2">
            <p className="text-xs text-gray-500">
              Version 1.0.0
            </p>
          </div>
        </div>
      </MobileMenu>
    </nav>
  );
};

export default Header;
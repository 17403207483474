// src/config/samples.js

// Export categories
export const SAMPLE_CATEGORIES = {
    PORTRAIT: 'Portrait',
    PRODUCT: 'Product',
    LANDSCAPE: 'Landscape',
    OLD_PHOTO: 'Old photograph',
    ART: 'Art',
    ARCHITECTURE: 'Architecture'
};

// Export samples array
export const samples = [
    {
        id: 1,
        title: "Portrait Enhancement",
        description: "Perfect for old photos",
        before: "/samples/portrait-before.jpeg",
        after: "/samples/portrait-after.jpeg",
        category: SAMPLE_CATEGORIES.PORTRAIT,
        details: "Ideal for restoring and enhancing portrait photographs with improved facial details and skin tones."
    },
    {
        id: 2,
        title: "Food Photography",
        description: "Ideal for Food vlogs",
        before: "/samples/food-before.jpeg",
        after: "/samples/food-after.jpeg",
        category: SAMPLE_CATEGORIES.PRODUCT,
        details: "Perfect for e-commerce and food bloggers, bringing out the texture and details in food photography."
    },
    {
        id: 3,
        title: "Landscape Enhancing",
        description: "Enhance scenic views",
        before: "/samples/landscape-before.jpeg",
        after: "/samples/landscape-after.jpeg",
        category: SAMPLE_CATEGORIES.LANDSCAPE,
        details: "Bring out the fine details in landscape photography, from distant mountains to delicate foliage."
    },
    {
        id: 4,
        title: "Old Photographs",
        description: "Recover details from black and white photos",
        before: "/samples/document-before.jpeg",
        after: "/samples/document-after.jpeg",
        category: SAMPLE_CATEGORIES.OLD_PHOTO,
        details: "Restore and enhance vintage photographs while preserving their historical character."
    },
    {
        id: 5,
        title: "Art Upscaling",
        description: "Digital art enhancement",
        before: "/samples/art-before.jpeg",
        after: "/samples/art-after.jpeg",
        category: SAMPLE_CATEGORIES.ART,
        details: "Perfect for digital artists looking to increase the resolution of their artwork without losing quality."
    },
    {
        id: 6,
        title: "Product photography",
        description: "Sharpen Product details",
        before: "/samples/bottle-before.jpeg",
        after: "/samples/bottle-after.jpeg",
        category: SAMPLE_CATEGORIES.ARCHITECTURE,
        details: "Enhance architectural photography with improved structural details and textures."
    }
];

// Export helper functions
export const getRandomSamples = (count = 3) => {
    const shuffled = [...samples].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
};

export const getSamplesByCategory = (category) => {
    if (category === 'All') return samples;
    return samples.filter(sample => sample.category === category);
};
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { isDevelopment, logInDev } from '../utils/environment';

export const initGA = () => {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const ADS_CONVERSION_ID = process.env.REACT_APP_GOOGLE_ADS_ID; // Add this to your .env file
  
  // Initialize dataLayer
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;

  // Load the Google tag script
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
  document.head.appendChild(script);

  // Initialize gtag
  gtag('js', new Date());

  // Configure GA4
  gtag('config', GA_MEASUREMENT_ID, {
    send_page_view: false
  });

  // Configure Google Ads if ID is available
  if (ADS_CONVERSION_ID) {
    gtag('config', ADS_CONVERSION_ID);
    
    if (isDevelopment) {
      logInDev('🎯 Google Ads Tag Initialized:', ADS_CONVERSION_ID);
    }
  }
};

// Track specific Google Ads conversions
export const trackAdsConversion = (conversionId, conversionLabel, value = 0) => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      'send_to': `${process.env.REACT_APP_GOOGLE_ADS_ID}/${conversionLabel}`,
      'value': value,
      'currency': 'USD',
    });

    if (isDevelopment) {
      logInDev('💰 Conversion Tracked:', {
        conversionId,
        conversionLabel,
        value
      });
    }
  }
};

const GoogleAnalytics = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      // Extract UTM and Google Ads parameters
      const trackingParams = {
        campaign_id: searchParams.get('utm_id'),
        campaign_name: searchParams.get('utm_campaign'),
        campaign_source: searchParams.get('utm_source'),
        campaign_medium: searchParams.get('utm_medium'),
        campaign_term: searchParams.get('utm_term'),
        campaign_content: searchParams.get('utm_content'),
        gclid: searchParams.get('gclid'),
        gclsrc: searchParams.get('gclsrc'),
        dclid: searchParams.get('dclid'),
      };

      // Filter out null/undefined values
      const cleanParams = Object.fromEntries(
        Object.entries(trackingParams).filter(([_, v]) => v != null)
      );

      // Log parameters in development
      if (isDevelopment) {
        logInDev('📊 Campaign Parameters:', cleanParams);
      }

      // Send pageview with tracking data
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: document.title,
        page_location: window.location.href,
        ...cleanParams
      });
    }
  }, [location, searchParams]);

  return null;
};

export default GoogleAnalytics;
// src/hooks/useFileConversion.js
import { useState, useCallback } from 'react';
import { 
  trackConversionStart, 
  trackConversionComplete, 
  trackConversionError,
  trackFileUpload 
} from '../utils/analytics';
import { getCustomFileName } from '../utils/conversionUtils';
import { isDevelopment, logInDev } from '../utils/environment';

const API_URL = process.env.REACT_APP_API_URL;
const DIRECT_UPLOAD_LIMIT = 6 * 1024 * 1024;

export const useFileConversion = (outputFormat) => {
  const [conversionState, setConversionState] = useState({
    phase: null,
    currentFile: 0,
    totalFiles: 0,
    error: null,
    convertedFiles: null,
    isProcessing: false
  });

  const convertFiles = useCallback(async (inputFiles) => {
    if (!inputFiles?.length) {
      logInDev('❌ No files provided for conversion');
      return;
    }

    const startTime = performance.now();
    
    try {
      setConversionState({
        phase: 'transferring',
        currentFile: 0,
        totalFiles: inputFiles.length,
        error: null,
        convertedFiles: null,
        isProcessing: true
      });
  
      trackConversionStart(inputFiles.length);
  
      // Sort files by size
      const largeFiles = inputFiles.filter(f => f && f.size > DIRECT_UPLOAD_LIMIT);
      const smallFiles = inputFiles.filter(f => f && f.size <= DIRECT_UPLOAD_LIMIT);
      
      logInDev(`📊 Files breakdown - Large: ${largeFiles.length}, Small: ${smallFiles.length}`);
  
      const s3Keys = [];
      const convertFormData = new FormData();
  
      // Process large files
      for (let i = 0; i < largeFiles.length; i++) {
        setConversionState(prev => ({
          ...prev,
          currentFile: i + 1
        }));
  
        try {
          const key = await handleLargeFileUpload(
            largeFiles[i],
            i,
            largeFiles.length
          );
          if (key) s3Keys.push(key);
        } catch (error) {
          logInDev('❌ Error uploading large file:', error);
          throw new Error('Failed to upload file. Please try again.');
        }
      }
  
      // Add small files
      smallFiles.forEach(file => {
        if (file) {
          logInDev(`📄 Adding direct file: ${file.name}`);
          convertFormData.append('files', file);
        }
      });
  
      // Add S3 keys if any
      if (s3Keys.length > 0) {
        logInDev(`🔑 Adding S3 keys: ${s3Keys.length}`);
        convertFormData.append('file_keys', s3Keys.join(','));
      }
  
      // Add conversion parameters
      convertFormData.append('quality', '85');
      convertFormData.append('page', '1');
  
      // Start conversion
      setConversionState(prev => ({
        ...prev,
        phase: 'converting'
      }));
  
      logInDev('🔄 Starting conversion process...');
      const response = await fetch(`${API_URL}/convert/${outputFormat}`, {
        method: 'POST',
        body: convertFormData,
      });
  
      if (!response.ok) {
        throw new Error('Conversion failed. Please try again.');
      }
  
      const blob = await response.blob();
      
      if (!blob || blob.size === 0) {
        throw new Error('Received empty response. Please try again.');
      }
  
      const isZip = response.headers.get('content-type')?.includes('zip');
      logInDev('📦 Response type:', response.headers.get('content-type'));
      
      const convertedFiles = [{
        name: isZip 
          ? `ilikeimg_batch_${new Date().toISOString().replace(/[-:]/g, '')}.zip`
          : getCustomFileName('converted', outputFormat),
        blob
      }];
  
      const endTime = performance.now();
      const conversionTime = endTime - startTime;

      trackConversionComplete(
        conversionTime,
        inputFiles.length,
        outputFormat,
        true,
        null,
        blob.size
      );
      logInDev('✅ Conversion completed successfully');
  
      setConversionState({
        phase: null,
        currentFile: 0,
        totalFiles: 0,
        error: null,
        convertedFiles,
        isProcessing: false
      });
  
      return convertedFiles;
  
    } catch (error) {
      logInDev('❌ Conversion error:', error);
      trackConversionError(error);

      let errorMessage = isDevelopment 
        ? error.message 
        : 'An error occurred during conversion. Please try again.';

      setConversionState({
        phase: null,
        currentFile: 0,
        totalFiles: 0,
        error: errorMessage,
        convertedFiles: null,
        isProcessing: false
      });
    }
  }, [outputFormat]);

  const reset = useCallback(() => {
    setConversionState({
      phase: null,
      currentFile: 0,
      totalFiles: 0,
      error: null,
      convertedFiles: null,
      isProcessing: false
    });
  }, []);

  return {
    ...conversionState,
    convertFiles,
    reset
  };
};

// Helper function for large file uploads
const handleLargeFileUpload = async (file, currentIndex, totalFiles) => {
  logInDev(`📤 Processing file ${currentIndex + 1}/${totalFiles}: ${file.name}`);
  
  try {
    logInDev('🔑 Requesting upload URL...');
    const response = await fetch(`${API_URL}/generate-upload-url`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filename: file.name,
        contentType: file.type,
        fileSize: file.size
      })
    });

    if (!response.ok) {
      throw new Error('Upload preparation failed');
    }

    const data = await response.json();
    logInDev('✅ Received upload configuration');

    if (data.useDirect) {
      return null;
    }

    const formData = new FormData();
    Object.entries(data.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);

    trackFileUpload(file);
    logInDev('🚀 Transferring file...');
    
    const uploadResponse = await fetch(data.url, {
      method: 'POST',
      body: formData
    });

    if (!uploadResponse.ok) {
      throw new Error('File transfer failed');
    }

    logInDev('✅ File transfer complete');
    return data.key;
  } catch (error) {
    logInDev('❌ Upload error:', error);
    throw new Error('File upload failed. Please try again.');
  }
};
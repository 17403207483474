import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
  ShieldCheck, 
  Zap, 
  Clock, 
  CheckCircle, 
  ArrowLeft,
  Loader,
  AlertCircle
} from 'lucide-react';
import { AuthContext } from '../../contexts/AuthContext';
import { trackEvent } from '../../utils/analytics';
import { paymentService } from '../../services/PaymentService';

const CheckoutProgress = ({ stage }) => {
  const stages = ['processing', 'redirecting', 'success'];
  const currentIndex = stages.indexOf(stage);

  return (
    <div className="max-w-md mx-auto">
      <div className="flex items-center justify-between mb-4">
        {stages.map((s, index) => (
          <React.Fragment key={s}>
            {index > 0 && (
              <div 
                className={`flex-1 h-1 mx-2 rounded ${
                  index <= currentIndex ? 'bg-purple-500' : 'bg-gray-200'
                }`} 
              />
            )}
            <div 
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                index <= currentIndex ? 'bg-purple-500 text-white' : 'bg-gray-200 text-gray-400'
              }`}
            >
              {index + 1}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const LoadingState = ({ message }) => (
  <div className="text-center">
    <Loader className="w-12 h-12 text-purple-500 animate-spin mx-auto mb-4" />
    <p className="text-gray-600">{message}</p>
  </div>
);

const SuccessState = ({ onContinue }) => (
  <div className="text-center">
    <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-6" />
    <h2 className="text-2xl font-bold text-gray-900 mb-2">
      Welcome to Premium!
    </h2>
    <p className="text-gray-600 mb-6">
      Your account has been successfully upgraded. Enjoy all the premium features!
    </p>
    <button
      onClick={onContinue}
      className="inline-flex items-center px-6 py-3 border border-transparent 
               text-base font-medium rounded-md shadow-sm text-white 
               bg-purple-600 hover:bg-purple-700 focus:outline-none 
               focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
    >
      Continue to Dashboard
    </button>
  </div>
);

const ErrorState = ({ message, onRetry }) => (
  <div className="text-center">
    <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-6" />
    <h2 className="text-xl font-semibold text-gray-900 mb-2">
      Something went wrong
    </h2>
    <p className="text-gray-600 mb-6">{message}</p>
    {onRetry && (
      <button
        onClick={onRetry}
        className="inline-flex items-center px-4 py-2 border border-transparent 
                 text-base font-medium rounded-md text-purple-700 bg-purple-100 
                 hover:bg-purple-200 focus:outline-none focus:ring-2 
                 focus:ring-offset-2 focus:ring-purple-500"
      >
        Try Again
      </button>
    )}
  </div>
);


const CheckoutPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stage, setStage] = useState('processing');
  const [pricingInfo, setPricingInfo] = useState(null);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const data = await paymentService.getPricing();
        setPricingInfo(data);
      } catch (err) {
        setError('Failed to load pricing information');
      }
    };

    fetchPricing();
  }, []);

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    if (sessionId) {
      setStage('success');
      trackEvent('checkout_success', { session_id: sessionId });
    }
  }, [searchParams]);

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);
    try {
      trackEvent('checkout_started', { user_id: user.email });
      
      const { url } = await paymentService.createCheckoutSession(
        localStorage.getItem('accessToken')
      );

      setStage('redirecting');
      window.location.href = url;
    } catch (err) {
      setError(err.message || 'Failed to start checkout process');
      trackEvent('checkout_error', { 
        error: err.message,
        user_id: user.email 
      });
    } finally {
      setLoading(false);
    }
  };

  if (!pricingInfo && !error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingState message="Loading pricing information..." />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <ErrorState 
          message={error}
          onRetry={() => window.location.reload()}
        />
      </div>
    );
  }

  if (stage === 'success') {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <SuccessState onContinue={() => navigate('/settings')} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Upgrade to Premium | ilikeimg</title>
      </Helmet>

      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Back Button */}
          <button
            onClick={() => navigate('/settings')}
            className="inline-flex items-center text-gray-500 hover:text-gray-700 mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Settings
          </button>

          {/* Progress Bar */}
          <CheckoutProgress stage={stage} />

          {/* Main Content */}
          <div className="max-w-4xl mx-auto mt-8">
            <div className="bg-white rounded-2xl shadow p-8">
              <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  Upgrade to Premium
                </h1>
                <p className="text-gray-600 max-w-2xl mx-auto">
                  Get access to all premium features and unlock your full creative potential
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-8 mb-8">
                <div className="bg-purple-50 rounded-xl p-6">
                  <h2 className="text-lg font-semibold text-purple-900 mb-4">
                    What you'll get:
                  </h2>
                  <ul className="space-y-4">
                    <li className="flex items-start">
                      <Zap className="w-5 h-5 text-purple-500 mt-1 mr-3" />
                      <div>
                        <p className="font-medium text-purple-900">
                          {pricingInfo.premiumCreditsDaily} Daily Credits
                        </p>
                        <p className="text-sm text-purple-700">
                          4x more than free plan
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <ShieldCheck className="w-5 h-5 text-purple-500 mt-1 mr-3" />
                      <div>
                        <p className="font-medium text-purple-900">
                          Priority Processing
                        </p>
                        <p className="text-sm text-purple-700">
                          Skip the queue, get faster results
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start">
                      <Clock className="w-5 h-5 text-purple-500 mt-1 mr-3" />
                      <div>
                        <p className="font-medium text-purple-900">
                          Larger File Sizes
                        </p>
                        <p className="text-sm text-purple-700">
                          Up to {(pricingInfo.premiumSizeLimit / (1024 * 1024)).toFixed(0)}MB per file
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <h2 className="text-lg font-semibold text-gray-900 mb-4">
                    Subscription Details
                  </h2>
                  <div className="mb-6">
                    <div className="flex items-baseline mb-2">
                      <span className="text-3xl font-bold">
                        ${pricingInfo.premiumPrice}
                      </span>
                      <span className="text-gray-500 ml-2">/ month</span> 
                      </div>
                    <p className="text-sm text-gray-500">
                      Cancel anytime. No commitments.
                    </p>
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2" />
                      Instant access to premium features
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2" />
                      Secure payment via Stripe
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2" />
                      14-day money-back guarantee
                    </div>
                  </div>

                  <button
                    onClick={handleCheckout}
                    disabled={loading}
                    className="w-full mt-6 px-6 py-3 border border-transparent 
                             text-base font-medium rounded-md text-white 
                             bg-purple-600 hover:bg-purple-700 
                             focus:outline-none focus:ring-2 focus:ring-offset-2 
                             focus:ring-purple-500 disabled:opacity-50 
                             disabled:cursor-not-allowed transition-all duration-150
                             flex items-center justify-center"
                  >
                    {loading ? (
                      <>
                        <Loader className="w-5 h-5 animate-spin mr-2" />
                        Processing...
                      </>
                    ) : (
                      'Upgrade Now'
                    )}
                  </button>
                </div>
              </div>

              {/* FAQ Section */}
              <div className="mt-12 border-t border-gray-200 pt-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  Frequently Asked Questions
                </h2>
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      When will I be charged?
                    </h3>
                    <p className="text-gray-600">
                      You'll be charged immediately for your first month. 
                      Subsequent charges will occur on the same day each month.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      Can I cancel anytime?
                    </h3>
                    <p className="text-gray-600">
                      Yes, you can cancel your subscription at any time. 
                      You'll continue to have premium access until the end of your billing period.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      Is there a free trial?
                    </h3>
                    <p className="text-gray-600">
                      We offer a 14-day money-back guarantee. If you're not satisfied, 
                      we'll refund your payment - no questions asked.
                    </p>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-2">
                      What payment methods do you accept?
                    </h3>
                    <p className="text-gray-600">
                      We accept all major credit and debit cards including Visa, 
                      Mastercard, and American Express.
                    </p>
                  </div>
                </div>
              </div>

              {/* Trust Badges */}
              <div className="mt-12 border-t border-gray-200 pt-8">
                <div className="grid grid-cols-3 gap-4 items-center justify-items-center">
                  <div className="text-center">
                    <ShieldCheck className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                    <p className="text-sm text-gray-500">Secure Payment</p>
                  </div>
                  <div className="text-center">
                    <Clock className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                    <p className="text-sm text-gray-500">Instant Access</p>
                  </div>
                  <div className="text-center">
                    <Zap className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                    <p className="text-sm text-gray-500">Premium Support</p>
                  </div>
                </div>
              </div>

              {/* Money Back Guarantee */}
              <div className="mt-8 bg-green-50 rounded-lg p-4 flex items-start">
                <CheckCircle className="w-5 h-5 text-green-500 mt-0.5 mr-3 flex-shrink-0" />
                <div>
                  <h3 className="text-green-800 font-medium">
                    14-Day Money Back Guarantee
                  </h3>
                  <p className="text-green-700 text-sm mt-1">
                    Try Premium risk-free. If you're not satisfied within 14 days, 
                    we'll refund your payment - no questions asked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutPage;
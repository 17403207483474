import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { paymentService } from '../services/PaymentService';
import { useAuth } from '../contexts/AuthContext';

export const CreditsContext = createContext({
  credits: 0,
  refreshCredits: () => {},
  useCredits: async () => {},
});

export const CreditsProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);
  const { user } = useAuth();

  const fetchCredits = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) return;
      
      const data = await paymentService.getUserCredits(token);
      setCredits(data.credits);
      return data.credits;
    } catch (error) {
      console.error('Failed to fetch credits:', error);
      return 0;
    }
  }, []);

  const refreshCredits = useCallback(async () => {
    return fetchCredits();
  }, [fetchCredits]);

  const useCredits = useCallback(async (amount) => {
    try {
      const token = localStorage.getItem('accessToken');
      const result = await paymentService.useCredits(amount, token);
      setCredits(result.remainingCredits);
      return true;
    } catch (error) {
      console.error('Failed to use credits:', error);
      return false;
    }
  }, []);

  // Fetch credits when user changes or logs in
  useEffect(() => {
    if (user) {
      fetchCredits();
    } else {
      setCredits(0); // Reset credits when user logs out
    }
  }, [user, fetchCredits]);

  return (
    <CreditsContext.Provider value={{ credits, refreshCredits, useCredits }}>
      {children}
    </CreditsContext.Provider>
  );
};

export const useCredits = () => {
  const context = useContext(CreditsContext);
  if (!context) {
    throw new Error('useCredits must be used within a CreditsProvider');
  }
  return context;
};